import React, { Component } from 'react'
import ASNForm from './ASNForm'
import ASN from '../services/ASN'

export class ASNFormView extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.getASN = this.getASN.bind(this)
  }
  handleSubmit(lp, data) {
      return ASN.amendSubmit(lp, data);
  }
  getASN(lp) {
    return ASN.get(
      lp,
      this.props.match.params.POid,
      this.props.match.params.DocumentNumber
    ).then((response) => {
      return response
    })
  }
  render() {
    return (
      <ASNForm
        disabled={true}
        handleSubmit={this.handleSubmit}
        handleDraft={this.handleSubmit}
        getASN={this.getASN}
        poId={this.props.match.params.POid}
        lp={this.props.match.params.LPid}
      />
    )
  }
}
