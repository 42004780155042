import RestUtilities from './Base'
import AuthStore from '../stores/Auth'
import { ResponseObj } from './Base'

import jwt_decode from 'jwt-decode'
import { Routes } from '../App'
import UserInfo from '../viewModels/UserInfo'

export default class Auth {
  static isSignedInIn() {
    return !!AuthStore.getToken()
  }
  static getToken() {
    return AuthStore.getToken()
  }

  static getRole(token: string) {
    return Auth.getTokenField('http://schemas.microsoft.com/ws/2008/06/identity/claims/role')
  }

  static getLP(token: string) {
    return { id: Auth.getTokenField('lp_id'), name: Auth.getTokenField('lp_name') }
  }

  static getTokenField(field_name: string) {
    let token = Auth.getToken()
    if (token) {
      try {
        let decoded: any = jwt_decode(token)
        return decoded[field_name]
      }
      catch (e) {
        return ''
      }

    }
    return ''
  }

  static getFirstName() {
    return Auth.getTokenField('FirstName')
  }

  static getLastName() {
    return Auth.getTokenField('LastName')
  }

  static getEmail() {
    return Auth.getTokenField(
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
    )
  }

  static getExpiry() {
    return Auth.getTokenField(
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/expiration'
    )
  }

  static async getMFAConfirm(mode: string, contact: string) : Promise<string> {
    return RestUtilities.get(`/api/auth/getMFAConfirm?mode=${mode}&contact=${contact}`).then((response) => {
      return !response.is_error ? response.content : "failed";
    });
  }

  static async UpdateMFAInfo(mode: string, contact: string): Promise<boolean> {
    var response = await RestUtilities.post(`/api/auth/updateMFAInfo`,
      `mode=${mode}&contact=${contact}`);

    return !response.is_error ? response.content : "failed";
  }

  static signOut() {
    AuthStore.clear()
    sessionStorage.clear();
  }

  static signInOrRegister(
    email: string,
    password: string,
    mfa: string | undefined,
    rememberMe: boolean,
    isRegister: boolean = false
  ) {
    return RestUtilities.post(
      `/api/auth/${isRegister ? 'register' : 'login'}`,
      `username=${email}&password=${password}${mfa == undefined ? '' : '&mfa=' + mfa}${rememberMe ? '&rememberMe=true': ''}${!isRegister ? '&grant_type=password' : ''
      }`
    ).then((response) => {
      if (!response.is_error) {
        AuthStore.setResponse(response.content)
      }
      return response
    })
  }

  static signIn(email: string, password: string, mfa: string|undefined, rememberMe: boolean) {
    // return this.mockSignInOrRegister(email, password, false)
    return this.signInOrRegister(email, password, mfa, rememberMe, false);
  }
  /*
  register(email: string, password: string) {
    return this.signInOrRegister(email, password, true)
  }
  */

  static refreshToken() {
    return RestUtilities.get('/api/auth/refresh').then((response) => {
      if (!response.is_error) {
        AuthStore.setResponse(response.content)
      }
      return response
    })
  }

  static changePassword(currentPassword: string, password: string) {
    return RestUtilities.post('/api/auth/changePassword', {
      password: password,
      currentPassword: currentPassword
    }).then((response) => {
      return response
    })
  }

  static getLoggedInUserInfo(): Promise<UserInfo|void> {
    if (!Auth.isSignedInIn())
      return Promise.resolve();

    return RestUtilities.get('/api/auth/getUserInfo').then((response: any) => {
      var retVal = response.content;

      if (retVal) {
        retVal.expiry = retVal.expiry ? new Date(retVal.expiry + "Z") : null;
        retVal.lastChanged = retVal.lastChanged ? new Date(retVal.lastChanged + "Z") : null;
        retVal.previousSignOn = retVal.previousSignOn ? new Date(retVal.previousSignOn + "Z") : null;
        retVal.lastSignOn = retVal.lastSignOn ? new Date(retVal.lastSignOn + "Z") : null;
      }

      return retVal as UserInfo;
    });
  }
}
