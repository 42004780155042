import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

interface Props {
  type: string
  text: string
  show?: boolean
}

const Alert = (props: Props) => {
  const [show, setShow] = useState(props.show)

  useEffect(() => {
    setShow(props.show);
  }, [props.show])

  return (
    <div
      className={
        classNames({
          alert: true,
          ['alert-' + props.type]: true,
          'alert-dismissable': true,
          'fade': true,
          show: show
        })
      }
      role="alert">
      {props.text}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => {setShow(false)}}>
        <FontAwesomeIcon icon="times" />
      </button>
    </div>
  )
}

export default Alert
