import React, { useState, useContext }  from 'react';
import CoaModalView from './CoAModalView'
import CoAUploader from '../services/CoAUpload'

interface Props {
  coAFileName: string
  close: () => void
  asnStatus: string
  header: any
  asn: any
  lp: string
  harvestDate: string
  lotCode: string
  sku: any
  packagingDate:string,

}

const CoAModal = (props: Props) => {
  const [requesting, setRequesting] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [uploading, setUploading] = useState(false);

  const onCoAUpdate = async (selectedFiles: FileList|null) => {
    if (selectedFiles) {
      setUploading(true);
      const currFile = selectedFiles[0];
      if (currFile !== undefined && currFile.type === 'application/pdf') {
        return CoAUploader.sendCoA(
          currFile,
          props.harvestDate,
          props.lotCode,
          props.sku,
          props.lp,
          props.header,
          props.asn,
          props.coAFileName
        ).then((response) => {
          if (response instanceof Error) {
            throw Error('Issue Uploading CoA')
          }
          props.close();
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setUploading(true);
        });
      }
    }
  }

  return (
      <CoaModalView
        asn={props.asn}
        coAFileName={props.coAFileName}
        close={props.close}
        uploader={onCoAUpdate}
        asnStatus={props.asnStatus}
        uploading={uploading}
        header={props.header}
      />
    );
}

export default CoAModal;
