import React from 'react'
import classNames from 'classnames'

import Loading from '../loading/Loading'
import { NONE_SELECTED } from '../select'
import { LP } from './LPContext'

import './lpLoader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  lps: LP[]
  disabled: boolean
  loading: boolean
  done: boolean
  errorMsg: string
  selectedLP: string
  handleSelected: (e: React.ChangeEvent<HTMLSelectElement>) => void
  setConfirmed: (confirmed: boolean) => void
  confirmed: boolean
}

const LPLoaderView = (props: Props) => {
  let lpOptions = [
    <option key={NONE_SELECTED} value={NONE_SELECTED}>
      {'Select an LP ...'}
    </option>,
  ]
  
  const sortedLps  = props.lps.sort((lp1, lp2) => {
    return lp1.id - lp2.id;
  });

  const confirm = () => props.setConfirmed(true);
  const unconfirm = () => props.setConfirmed(false);

  for (let i = 0; i < props.lps.length; i++) {
    lpOptions.push(
      <option key={i + 1} value={i}>
        {`${sortedLps[i].id} - ${sortedLps[i].name}`}
      </option>
    )
  }

  return (
    <div className="row">
      <div className="col-5">
        <div className="form-group">
          <label htmlFor="LP">Select LP</label>
          <select
            required
            name="LP"
            className={classNames({
              'form-control': true,
              'd-none': props.loading,
            })}
            disabled={
              props.disabled || !props.done || props.loading || props.confirmed
            }
            value={props.selectedLP}
            onChange={props.handleSelected}
          >
            {lpOptions}
          </select>

          <Loading show={props.loading} />
          {props.errorMsg && (
            <span className="text-danger">{props.errorMsg}</span>
          )}
        </div>
      </div>
      <div className="col-7">
        <div className="confirm">
          <button
            className={classNames({
              'btn btn-orange': true,
              'd-none': props.confirmed,
            })}
            disabled={
              props.disabled ||
              !props.done ||
              props.loading ||
              props.confirmed ||
              props.selectedLP === NONE_SELECTED
            }
            onClick={confirm}
          >
            Select LP
          </button>
          <button
            className={classNames({
              'btn btn-link': true,
              'd-none': !props.confirmed,
            })}
            disabled={!props.confirmed}
            onClick={unconfirm}
          >
            <FontAwesomeIcon icon="edit" />
            &nbsp;Change LP
          </button>
        </div>
      </div>
    </div>
  )
}

export default LPLoaderView
