import React, { Context } from 'react'

export interface LP {
  id: number
  name: string
}

export const DEFAULT_LP = { id: -1, name: ''}

export interface LPContextInterface {
  LP: LP
  setLP: (LP: LP) => void
  displayName? : string;
}

const LPContext: Context<LPContextInterface> = React.createContext<LPContextInterface>({
  LP: DEFAULT_LP,
  setLP: (LP:LP) => {}
})

LPContext.displayName = 'LPContext'

export default LPContext
