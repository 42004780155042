// import { DefaultAzureCredential } from "@azure/identity";
import { BlobServiceClient } from "@azure/storage-blob";
import ASN from '../services/ASN'
import RestUtilities from "./Base";
// import AzureStorageBlob from "@azure/storage-blob";

export default class CoAUploader {
    static async amendAsnDetails(coAFileName: string, currentDate:string, coAFTPFileName:string, lp: string, header: any, asn: any) {

      const data  = {
        QvUpf: "Y",
        QvUpt:currentDate,
        QvCoa: coAFileName,
        QvCat: coAFTPFileName,
        Header: header
      }      
      return ASN.uploadCoAData(lp, asn.lineID, header.costCenter, header.documentNumber, data);
    }

    static async sendCoA(file: File, harvestDate:string, lotCode: string, sku: any, lp:string, header: any, asn: any, originalFileName?: string) {
      return new Promise(async (resolve, reject) => {
        const currentDate = new Date().toISOString().slice(-24).replace(/\D/g,'').slice(0, 14);
        const trimmedLP = lp.trim();
        var trimmedLot = lotCode.trim();
        if (trimmedLot.length > 20 ){
          trimmedLot = trimmedLot.substring(0, 19)
        }
        const trimmedSku = sku.trim();
        const asnNumber = `${asn.documentNumber}`.trim();
        const blobName = originalFileName == null ? `${trimmedSku}+${trimmedLot}+${harvestDate}+${currentDate}.pdf` : originalFileName;
  
        var fileByteArray: any  = [];
        var reader = new FileReader();
        reader.onload = 
          async (evt: any) => {
            if (evt.target.readyState == FileReader.DONE) {
               var arrayBuffer = evt.target.result,
                   array = new Uint8Array(arrayBuffer);
               for (var i = 0; i < array.length; i++) {
                   fileByteArray.push(array[i]);
                }

                const uploadResponse = await RestUtilities.post("/api/asn/uploadCoA", {
                  coaFile: fileByteArray,
                  blobFolder: `${trimmedLP}-${asnNumber}`,
                  storageFileName: blobName
                });
          
                if (!uploadResponse.is_error) {
                  const amendResponse = await this.amendAsnDetails(blobName, currentDate, file.name.trim(), lp, header, asn);
                  if (amendResponse.ok) {
                    resolve(amendResponse);
                  } else {
                    resolve(blobName);
                  }        
                } else {
                  resolve(uploadResponse.statusText);
                }
            }
          };
        reader.readAsArrayBuffer(file);        
      });
    }
}
