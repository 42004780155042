import React, { useState, useEffect, useContext } from 'react'

import PO from '../services/PO'
import POLoaderView from './poLoaderView'
import { NONE_SELECTED } from '../select'
import LPContext from '../lpLoader/LPContext'

export interface POId {
  text: string
  value: string
  days: number
  closingDate: number
}

interface Props {
  default?: string
  disabled: boolean
  handleSelectedPO: (po: string, closingDate:Date) => void
}

const POLoader = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [selected, setSelected] = useState(NONE_SELECTED)
  const [errorMsg, setErrorMsg] = useState('')
  const [poIDs, setPOIDs] = useState<POId[]>([])

  const lpContext = useContext(LPContext)

  const handleSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let POid = e.target.value
    if (POid !== NONE_SELECTED) {
      const closingstring = poIDs.find(x => x.value === POid)?.closingDate + "";
      const closingDate = new Date(+closingstring.substring(0, 4), +closingstring.substring(4, 6) - 1, +closingstring.substring(6, 8))
      props.handleSelectedPO(POid, closingDate)
      setSelected(POid)
    }
  }

  const load = async () => {
    if(props.default) {
      setSelected(props.default)
    }
    else {
      setErrorMsg('')
      setLoading(true)
      setDone(false)

      const response = await PO.getList(lpContext.LP.id)

      setLoading(false)

      if(response.is_error) {
        setErrorMsg(response.error_content)
      }
      else {
        setPOIDs(response.content.value)
        setDone(true)
      }
    }
  }

  const doLoad = () => {
    load()
  }

  useEffect(doLoad, [])

  return (
    <POLoaderView
      poIDs={poIDs}
      loading={loading}
      done={done}
      disabled={props.disabled}
      default={props.default}
      selectedPO={selected}
      handleSelected={handleSelected}
      errorMsg={errorMsg} />
  )
}

export default POLoader
