import React, { ChangeEvent } from 'react'
import classNames from 'classnames'

import Loading from '../loading/Loading'
import { NONE_SELECTED } from '../select'
import { POId } from './poLoader'

interface Props {
  poIDs: POId[]
  selectedPO: string
  default?: string
  disabled: boolean
  loading: boolean
  done: boolean
  errorMsg: string
  handleSelected: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const POLoaderView = (props: Props) => {
  const [selectedPo, setSeletedPo] = React.useState<POId | undefined>(undefined);

  let poSelectorOptions = [
    <option key={NONE_SELECTED} value={NONE_SELECTED} style={{color:"black"}}>
      {'Select PO# ...'}
    </option>,
  ]

  for (let i = 0; i < props.poIDs.length; i++) {
    poSelectorOptions.push(
      <option key={i + 1} value={props.poIDs[i].value} style={{ color: props.poIDs[i].days <= 7 ? "red" : props.poIDs[i].days <= 21 ? "#eed202" : "black" }}>
        {props.poIDs[i].text}
      </option>,
    )
  }

  if (props.default) {
    poSelectorOptions.push(
      <option value={props.default} key={props.default}>
        {props.default}
      </option>,
    )
  }

  let poSelectLabel = props.disabled ? 'PO# ' : 'PO#'
  let disabled =
    props.disabled || !props.done || props.loading || !!props.default

  const selectPo = (e:ChangeEvent<HTMLSelectElement>) => {
    const poId = props.poIDs.find(x => x.value == e.target.value);
    setSeletedPo(poId);
    props.handleSelected(e);
  }

  return (
    <div className="row">
      <div className="col-5">
        <div className="form-group">
          <label htmlFor="PO">{poSelectLabel}</label>
          <select
            required
            name="PO"
            className={classNames({
              'form-control': true,
              error: props.selectedPO === '0' && !props.default,
              'd-none': props.loading,
            })}
            disabled={disabled}
            value={props.selectedPO}
            onChange={selectPo}
            style={{ color: !selectedPo ? "black": selectedPo.days <= 7 ? 'red' : selectedPo.days <= 21 ? '#eed202' : 'black', paddingLeft: 15 }}>
            {poSelectorOptions}
          </select>
          <Loading show={props.loading} />
          {props.errorMsg && (
            <span className="text-danger">{props.errorMsg}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default POLoaderView
