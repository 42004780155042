import React, { Component } from "react"
import ASN from '../services/ASN'
import PO from '../services/PO'
import SecondaryASNFilterDropdown from './secondaryASNFilterDropdown'
import Loading from "../loading/Loading"


interface Props {
  lpId: number
  setFilterValue: (option: string) => void
  setPrimaryFilterValue: (option: string) => void
}

interface State {
  showSecondaryFilter: boolean
  primaryFilterValue: string
  loading: boolean
  options: JSX.Element[]
  firstOption: string
}

export class PrimaryASNFilterDropdown extends Component<Props, State> {
  secondaryFilterRef: React.RefObject<unknown>


  constructor(props: Readonly<Props>) {
    super(props)
    this.secondaryFilterRef = React.createRef()
    this.state = {
      showSecondaryFilter:false,
      primaryFilterValue:"none",
      loading: false,
      options: [],
      firstOption: 'none'
      // secondFilterValue:  "none"
    }
  }
  onFilterDropdownChange = async (e: React.FormEvent) => {
    const target = e.target as HTMLSelectElement
    const value: string = target.value
    if (target.value !== 'no filter') {      
      this.setState({
        primaryFilterValue: value,
        showSecondaryFilter: true,
        loading: true
      })

      await this.buildOptions(value)

    } else {
      this.setState({
        primaryFilterValue: value,
        showSecondaryFilter: false
      })
    }

    this.props.setPrimaryFilterValue(value);
  }

  getASNNumbers = () => {
    return ASN.getList(this.props.lpId, 0, 'none', 'default', 'none', false).then((response) => {
      const asnValues =  response.content.value;
      const asnNumbers = asnValues.map((asn: any) => {
        return asn.asnNumber.trim()
      }).filter((asnNumber: string) => asnNumber.length > 0)
      return asnNumbers.length > 0 ? asnNumbers : ['No ASN Numbers'];
    })
  }



  getPONumbers = () => {
    if (this.props.lpId !== undefined && this.props.lpId !== null ) {
      return PO.getList(this.props.lpId).then((response) => {
        return response.content.value.map((poId: any) => {
          return poId.value.trim()
        });
      })
    } else {
      throw Error('Missing Lp value')
    }

  }


  buildOptions = async (primaryFilter: string) => {    
    let options: string[] = [];
    // if (this.state.primaryFilterValue !== "none") {
      switch(primaryFilter.toLowerCase()) {
        case "none":
          this.setState({
            showSecondaryFilter: false
          })
          break
        case "ponumber":
          options = await this.getPONumbers()
          break
        case "asnnumber":
          options = await this.getASNNumbers()
          break
        default:
          options =
           ['BOOKED', 'SUBMITTED', 'DRAFT', 'REMOVED']
           break
      }      

      const elements: JSX.Element[] = options.map((item: string) => {
        return(
        <option key={`${item}`} value={`${item}`}>
          {`${item}`}
        </option>);
      })      

      this.setState({
        loading: false,
        options: elements,
        firstOption: options[0]
      });
    // return(
    //   {options}
    // )
  // }

    // this.setState({
    //   loading: false,
    //   selectOptions: elements
    // });
    // return(
    //   {options}
    // )
  }

  buildSecondaryFilterDropdown = () => {

    if (this.state.showSecondaryFilter && !this.state.loading) {
      return (

          <SecondaryASNFilterDropdown
            options={this.state.options}
            initialOption={this.state.firstOption}
            lp={this.props.lpId}
            label={`${this.state.primaryFilterValue}`}
            selectFunction={this.props.setFilterValue}
            setPrimaryFilterValue={this.props.setPrimaryFilterValue}
          />
      )
    } else if  (this.state.loading) {
      return (
        <Loading show={true}/>
      );
    }
  }

  render() {
    return (
      <div className="col-md">
        <div className="form-group">
          <label htmlFor="exampleFormControlSelect1">Filter By</label>
          <select
            onChange={(e) => this.onFilterDropdownChange(e)}
            className="form-control"
            id="exampleFormControlSelect1"
          >
            <option value="none">No Filter</option>
            <option value="asnnumber">ASN Number</option>
            <option value="orderstatus">Order Status</option>
            <option value="ponumber">PO Number</option>
          </select>
        </div>
        {this.buildSecondaryFilterDropdown()}
    </div>
    )
  }
}

export default PrimaryASNFilterDropdown
