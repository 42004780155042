import React from 'react'

import Loading from '../loading/Loading'
import './signin.scss'

interface Props {
  submitting: boolean
  error: string
  username: string
  password: string
  mfa: string | undefined
  rememberMe: boolean
  showMfa: boolean
  handleUsername: (value: string) => void
  handlePassword: (value: string) => void
  handleMfa: (value: string) => void
  handleRememberMe: (value: boolean) => void
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

const SignInForm = (props: Props) => {
  return (
    <div id="login" className="flex-container">
      <div className="flex-container-item flex-container-item--top"></div>
      <div className="flex-container-item">
        <div className="logo" />
        {props.showMfa && < div className="support" style={{ fontSize: ".75em", margin:"0" }}>
          Your account security is a priority for us and MFA is an essential tool to help keep your information safe. A login request was made on your account which requires an additional MFA sign-in code. The code is time sensitive, so please enter it promptly.
          <br /><br />
          Please re-enter your username, password and provide the code that was sent to you.
        </div>}
        {props.error && (
          <div className="alert alert-danger" role="alert">
            {props.error}
          </div>
        )}
        <form onSubmit={e => props.handleSubmit(e)}>
          <div className="form-group">
            <label htmlFor="username" className="form-control-label ">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={props.username}
              onChange={(e) => props.handleUsername(e.target.value)}
              className="form-control form-control-danger"
              placeholder="Username"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputPassword" className="form-control-label ">
              Password
            </label>
            <input
              type="password"
              id="inputPassword"
              value={props.password}
              onChange={(e) => props.handlePassword(e.target.value)}
              className="form-control"
              placeholder="Password"
              required
            />
          </div>
          {props.showMfa &&
            <>
            <div className="form-group">
              <label htmlFor="inputMfa" className="form-control-label ">
                Emailed Code
              </label>
              <input
                type="text"
                id="mfa"
                value={props.mfa}
                onChange={(e) => props.handleMfa(e.target.value)}
                className="form-control form-control-danger"
                placeholder="Email Code"
                required={props.showMfa}
              />
            </div>
            <div className="form-group">
              <label htmlFor="rememberMe" className="form-control-label ">
                Remember Me
              </label>
              <input
                type="checkbox"
                id="rememberMe"
                checked={props.rememberMe}
                onChange={(e) => props.handleRememberMe(!props.rememberMe)}
                className="form-control form-control-danger"
                style={{width:"inherit"}}
              />
            </div>
            </>
          }
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-orange btn-block"
              disabled={props.submitting}>
              Sign In
            </button>
          </div>
          <div className="form-group">
            <div className="support">
              <div className="support-title">For Support:</div>
              <div><a href="mailto:CannabisReceiving@Connect-Logistics.com">CannabisReceiving@Connect-Logistics.com</a></div>
              <div>825-402-6858</div>
            </div>
          </div>
          <div className="spinner">
            <Loading show={props.submitting} />
          </div>
        </form>
      </div>
      <div className="flex-container-item flex-container-item--bottom" />
    </div>
  )
}

export default SignInForm
