import React, { useState, useEffect, useContext } from 'react'

import ASN from '../services/ASN'
import AsnDialogContext, { MODAL_TYPE } from '../asnDialog/Context'
import GenericModal from '../modal/Modal'
import AsnDialog from '../asnDialog/AsnDialog'
import RoleContext, { CanEdit } from '../signin/RoleContext'
import LPContext, { DEFAULT_LP } from '../lpLoader/LPContext'
import SecondaryASNFilterDropdown from '../asnListDropdowns/secondaryASNFilterDropdown'
import PrimaryASNFilterDropdown from '../asnListDropdowns/primaryASNFilterDropdown'
import SortingDropdown from '../asnListDropdowns/sortingDropdown'
import InfiniteScrollWrapper from './InfiniteScrollWrapper'
import { Link } from 'react-router-dom'
import { TestScheduler } from 'jest'

const ASNList = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [error, setError] = useState(false)
  const [asn_list, setAsn_list] = useState<any[]>([])
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [showSecondaryFilter, setShowSecondary] = useState(false)
  const [primaryFilterValue, setPrimaryFilterValue] = useState('none')
  const [secondFilterValue, setShowSecondaryValue] = useState('none')
  const [showRemoved, setShowRemoved] = useState(false)
  const [sortValue, setSortValue] = useState('default')
  const [filterValue, setFilterValue] = useState('none')
  const [isFiltering, setIsFiltering] = useState(false)
  const roleContext = useContext(RoleContext)
  const lpContext = useContext(LPContext)
  const [currentLpId, setCurrentLpId] = useState(lpContext.LP.id)
  const [isReversed, setIsReversed] = useState(false)
  // const filterOption

  // const onFilterDropdownChange = (e: React.FormEvent) => {
  //   const target = e.target as HTMLSelectElement
  //   const value: string = target.value
  //   if (target.value !== 'no filter') {  
  //     setPrimaryFilterValue(value)
  //     setShowSecondary(true)
  //     setShowSecondaryValue(value)  
  //   } else {
  //     setShowSecondary(false)
  //   }
  // }

  const getASNs = (currOffset: number = offset) => {
    if (lpContext.LP.id !== DEFAULT_LP.id) {      
      return ASN.getList(lpContext.LP.id, currOffset, sortValue, filterValue, primaryFilterValue, isReversed)
    } else {
      return Promise.resolve({ content: { value: [] } });
    }
  }

  // useEffect(() => {
  //   if (hasMore) {
  //     lazyFetch(0, 0)
  //   }
  // }, [hasMore])


  const resetList = (lpChanged: boolean = false) => {
    setOffset(0)
    // setHasMore(true)
    activeFetch(0, 0, lpChanged)
    setLoading(false)
  }

  const lpLoaderResetList = (lpChanged: boolean = false) => {
    setOffset(0)
    setHasMore(true)
    activeFetch(0, 0, lpChanged)
    setLoading(false)
  }

  useEffect(() => {        
    if (hasMore && !showRemoved && !isFirstLoad) {      
      lazyFetch(0, 0)
    }
    else if (showRemoved || currentLpId !== lpContext.LP.id || isReversed === true) {      

      if (currentLpId!== lpContext.LP.id) {        
        setOffset(0)
        setCurrentLpId(lpContext.LP.id)        
      }
      if (showRemoved === true) {        
        resetList(currentLpId !== lpContext.LP.id)
      } else {        
        lpLoaderResetList(currentLpId !== lpContext.LP.id)
      }

    }

  }, [lpContext.LP, showRemoved, hasMore, isFiltering])



  const activeFetch = (startIndex: number, stopIndex: number, changedLP: boolean = false) => {    
    setLoading(true)
    setOffset(0)
    return getASNs(0)
      .then((response: any) => {        
        setLoading(false)
        if (response.is_error) {
          setError(true)
        } else {
          setError(false)

          if (response.content.value.length > 0) {
            let newValues = response.content.value.filter((asn: any) => {
              if (showRemoved || filterValue === "REMOVED") {
                return true
              } else {
                if (
                  asn.orderStatus.toLowerCase().trim() !== ASN.Status.removed
                ) {
                  return true
                }
              }

              return false
            })

            setOffset(offset + response.content.value.length)
            setAsn_list(newValues)

          } else if (((filterValue !== "none" && primaryFilterValue !== "none")
                          || changedLP)
                      && response.content.value.length === 0) {
            setAsn_list([]);
            setHasMore(false);
          } else {
            setHasMore(false)
          }
        }
      })
      .catch((e) => {
        setError(true)
        setLoading(false)
      })
  }

  const lazyFetch = (startIndex: number, stopIndex: number) => {

    if (!hasMore) {
      return Promise.resolve();
    }

    setLoading(true)
    return getASNs()
      .then((response) => {
        setLoading(false)

        if (response.is_error) {
          setError(true)
        } else {
          setError(false)

          if (response.content.value.length > 0) {
            let newValues = response.content.value.filter((asn: any) => {

              if (showRemoved) {
                return true
              } else {
                if (
                  asn.orderStatus.toLowerCase().trim() !== ASN.Status.removed
                ) {
                  return true
                }
              }

              return false
            })

            setOffset(offset + response.content.value.length)
            setAsn_list((asn_list) => [...asn_list, ...newValues])

          } else {
            setHasMore(false)
          }
        }
      })
      .catch((e) => {
        setError(true)
        setLoading(false)
      })
  }

  const applyFiltersAndSorting = () => {    
    setIsFiltering(true);
    resetList()
  }

  const clearFiltersAndSorting = () => {
    setIsFiltering(false);
    window.location.reload(true)
    resetList();

  }

  const [modalType, setModalType] = useState(MODAL_TYPE.CANCEL)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedAsn, setSelectedAsn] = useState({})

  return (
    <AsnDialogContext.Provider
      value={{
        isOpen: modalOpen,
        open: () => {
          setModalOpen(true)
        },
        close: () => {
          setModalOpen(false)
          resetList()
        },
        asn: selectedAsn,
        setASN: setSelectedAsn,
        mode: modalType,
        setMode: setModalType,
      }}
    >
      <div className="ASNList">
        <div className="row">
          <div className="col-md">
            <h1>
              ASN List{' '}
              {lpContext.LP.id !== DEFAULT_LP.id
                ? ` - ${lpContext.LP.id}`
                : ' - All LPs'}
            </h1>
          </div>
        </div>
        <div className="row" style={{ margin: '10px 0' }}>
          <div className="col-md">
            {CanEdit(roleContext.role) && lpContext.LP.id !== DEFAULT_LP.id && (
              <Link to={`/asn/create/${lpContext.LP.id}/`}>
                <button className="btn btn-orange">+ Create ASN</button>
              </Link>
            )}
            {lpContext.LP.id !== DEFAULT_LP.id && (
              <Link to={`/asn/${lpContext.LP.id}/lotCodes`}>
                <button
                  className="btn btn-orange"
                  style={{ marginLeft: '10px' }}
                >
                  + Manage Lot Codes
                </button>
              </Link>
            )}
          </div>
          <div className="col-md">
            <div className="float-right">
              <button className="btn btn-orange" onClick={() => resetList() }>
                Refresh ASN List
              </button>
            </div>
            <div className="custom-control custom-checkbox float-right" style={{ margin: '17px 5px' }}>
              <input
                type="checkbox"
                className="custom-control-input"
                id="checkRemoved"
                checked={showRemoved}
                onChange={() => {
                  if (showRemoved == true) {
                    setAsn_list([])
                    setIsFirstLoad(true)
                    setShowRemoved(false)
                  } else {
                    setShowRemoved(true)
                  }
                  resetList()
                }}
              />
              <label className="custom-control-label" htmlFor="checkRemoved">
                Show Removed
              </label>
            </div>
          </div>
        </div>
       { lpContext.LP.id !== DEFAULT_LP.id && (

        <div className="row" style={{ margin: '10px 0' }}>

          <PrimaryASNFilterDropdown
            lpId={lpContext.LP.id}
            setFilterValue={setFilterValue}
            setPrimaryFilterValue={setPrimaryFilterValue}
          />
          <div className="col-md">
            <SortingDropdown selectFunction={setSortValue} />
          </div>
          <div className="col-md">
            <div className="custom-control custom-checkbox float-center" style={{ paddingTop:"40px" }}>
              <input
                type="checkbox"
                className="custom-control-input"
                id="checkReversed"
                checked={isReversed}
                onChange={() => {                  
                  setIsReversed(!isReversed)

                }}
              />
              <label className="custom-control-label" htmlFor="checkReversed">
                Reverse Order
              </label>
              </div>

          </div>
          <div className="col-md">
            <div style={{"paddingTop": "23px"}}>
                <button className="btn btn-orange" style={{ "width": "100%" }} onClick={applyFiltersAndSorting}>Apply</button>
                {!isFiltering ? "" : < button className="btn btn-orange" style={{ "width": "100%" }} onClick={clearFiltersAndSorting}>Reset</button>}

            </div>
          </div>

        </div>
       )}

        <div className="ASNHeader">
          <div>ID</div>
          <div>Status</div>
          <div>PO #</div>
          <div>ASN #</div>
          <div>Created</div>
          <div>ETA</div>
          <div>Total Units</div>
          <div>Actions</div>
        </div>

        {/*
         * There's a bug with the infinite scroll list where if the initial load is empty, then
           future attempts to load the list do not occur.  This causes the entire component to re-render
           to force the initial request.
         */}
        {(asn_list.length > 0 || hasMore) && (
          <InfiniteScrollWrapper
            userRole={roleContext.role}
            isNextPageLoading={loading}
            items={asn_list}
            hasNextPage={hasMore}
            loadNextPage={lazyFetch}
          />
        )}
      </div>

      <AsnDialogContext.Consumer>
        {({ mode, asn, isOpen, close }) => {
          return (
            <GenericModal isOpen={isOpen}>
              <AsnDialog mode={mode} asn={asn} close={close} />
            </GenericModal>
          )
        }}
      </AsnDialogContext.Consumer>
    </AsnDialogContext.Provider>
  )
}

export default ASNList
