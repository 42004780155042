import React, { useState, useContext }  from 'react'

import PO from '../services/PO'
import { ResponseObj } from '../services/Base'
import LotCodeModalView, {
  MODAL_MODE,
} from './lotCodeModalView'
import LPContext from '../lpLoader/LPContext'
import { SKU } from '../skuLoader/skuLoaderView'

interface Props {
  lotCode?: any
  sku: SKU
  mode: number
  description: string
  close: () => void
}

const LotCodeModal = (props: Props) => {
  const [requesting, setRequesting] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const lpContext = useContext(LPContext)

  const submitForm = async (
    lotCodeId: string,
    harvestDate: string,
    thc: string,
    thcUnits: string,
    cbd: string,
    cbdUnits: string,
    originalLotCodeId: string | null,
    originalHarvestDate: string | null,
  ) => {
    if (requesting) return

    setRequesting(true)
    setError(false)
    setErrorMsg('')

    let lotCode: any = {
      sku: props.sku.sku,
      lotCodeId: lotCodeId,
      harvestDate: harvestDate,
      thc: thc,
      thcUnits: thcUnits,
      cbd: cbd,
      cbdUnits: cbdUnits,
    }

    let response: ResponseObj | null = null
    if (props.mode === MODAL_MODE.ADD) {
      response = await PO.createLotCode(lpContext.LP.id, lotCode)
    }
    else if (props.mode === MODAL_MODE.EDIT) {
      lotCode.originalHarvestDate = originalHarvestDate
      lotCode.originalLotCodeId = originalLotCodeId

      response = await PO.editLotCode(lpContext.LP.id, lotCode)
    }

    setRequesting(false)
    if(response){
      if(response.is_error) {
        setError(true)
        setErrorMsg(response.error_content);
      }
      else {
        setCompleted(true)
      }
    }
  }

  return (
    <LotCodeModalView
      mode={props.mode}
      lotCode={props.lotCode}
      requesting={requesting}
      completed={completed}
      error={error}
      errorText={errorMsg}
      submit={submitForm}
      description={props.description}
      sku={props.sku}
      close={props.close}
    />
  )
}

export default LotCodeModal
