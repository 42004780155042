import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import classNames from 'classnames'
import './AccountSettings.scss'
import Auth from '../services/Auth';

class AccountSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            confirmPassword: "",
            currentPassword: "",
            mfaCompare: "",
            mfaConfirm: "",
            confirmingMFA: false,
            userInfo: {
                        CNNumber: "",
                        FullName: "",
                        PreviousSignOn: null,
                        FailedAttemptCount: 0,
                        LastChanged: null,
                        Expiry: null,
                        mfaMode: "None",
                        multiFactorContact: "",
            },
            errors: [],
            mfaErrors: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMFASubmit = this.handleMFASubmit.bind(this);
        this.handleChanged = this.handleChanged.bind(this);
        this.handleMFAChanged = this.handleMFAChanged.bind(this);
        this.isPasswordValid = this.isPasswordValid.bind(this);
        this.isMFAValid = this.isMFAValid.bind(this);
        this.isConfirmPasswordValid = this.isConfirmPasswordValid.bind(this);
        this.isButtonDisabled = this.isButtonDisabled.bind(this);
        this.cancelMFAVerify = this.cancelMFAVerify.bind(this);
        this.submitMFAUpdate = this.submitMFAUpdate.bind(this);
    }

    componentDidMount() {
        this.getAccountData();
    }

    getAccountData() {
        return Auth.getLoggedInUserInfo().then((response) => {
            if (response) {
                this.setState({ userInfo: response });
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        Auth.changePassword(this.state.currentPassword, this.state.password)
            .then((response) => {
                if (response.is_error) {
                    alert(response.error_content);
                } else {
                    Auth.signOut();
                    localStorage.setItem("PasswordChangeRequired", false);
                    this.props.history.push('/signin');
                }
            });
    }

    handleMFASubmit(event) {
        event.preventDefault();
        if (this.state.userInfo.mfaMode == "None") {
            this.submitMFAUpdate();
        } else {
            this.verifyMFA().then((code) => {
                if (code == "failed") {
                    alert('could not update - please verify entered mfa type and contact info and try again');
                } else if (code == "unchanged") {
                    //nothing to do
                    alert('MFA information matches that already in system.');
                    return;
                } else {
                    var oldInfo =

                        this.setState((prevState) => (Object.assign(prevState, {
                            mfaConfirm: "",
                            mfaCompare: code,
                            confirmingMFA: true
                        })));
                }
            });
        }
    }

    submitMFAUpdate() {
        Auth.UpdateMFAInfo(this.state.userInfo.mfaMode, this.state.userInfo.multiFactorContact).then(() => {
            alert('successfully updated')
        }).catch(() => { alert('error occured updating - please verify information and try again'); }).finally(() => {
            this.setState((prevState) => (Object.assign(prevState, {
                mfaConfirm: "",
                mfaCompare: "",
                confirmingMFA: false
            })));
        });
    }

    async verifyMFA() {
        var confirmCode = await Auth.getMFAConfirm(this.state.userInfo.mfaMode, this.state.userInfo.multiFactorContact);
        return confirmCode;
    }

    handleChanged(event) {
        let name = event.target.name;
        let value = event.target.value;
        let newState = {};
        newState[name] = value;

        if (name === "password") {
            newState["errors"] = this.getErrors(value);
        } 

        this.setState((prevState) => (
            Object.assign(prevState, newState)
        ));

    }

    cancelMFAVerify(){
        this.setState((prevState) => (Object.assign(prevState, { confirmingMFA: false })));
    }

    handleMFAChanged(event) {
        let name = event.target.name;
        let value = event.target.value;
        let newState = { ...this.state.userInfo };
        newState[name] = value;
        let errors = [];

        if (name === "multiFactorContact" && this.state.userInfo.mfaMode == "SMS") {
            if (value) {
                //phone masking
                value = value.replace(/[^0-9]/g, '');

                if (value.length <= 3) {
                    //do nothing
                }
                else if (value.length > 3 && value.length < 7) {
                    value = `${value.substring(0, 3)}-${value.substring(3)}`
                } else {
                    value = `${value.substring(0, 3)}-${value.substring(3,6)}-${value.substring(6,Math.min(10,value.length))}`
                }
            }

            newState[name] = value;
            errors = this.getMFAErrors(this.state.userInfo.mfaMode, value);
        } else if (name === "mfaMode") {
            errors = this.getMFAErrors(value, this.state.userInfo.multiFactorContact);
        }



        this.setState((prevState) => ({ ...prevState, userInfo: newState, mfaErrors: errors }));
    }

    getMFAErrors(mode, value) {
        var newErrors = [];

        if (mode == "None") {
            return newErrors;
        }

        if (!value || value.trim().length == 0) {
            newErrors.push("MFA Contact must be provided");
        }


        if (value && mode == "Email") {
            if ((value.indexOf('@') <= 0) || (value.split('@').length < 2)) {
                newErrors.push("Email entered is invalid");
            } 
        } else if (value && mode == "SMS") {
            if (!/(\d){3}-(\d){3}-(\d){4}/.test(value)) {
                newErrors.push("Phone number entered is invalid. Please ensure of form 123-456-7890");
            }
        }

        return newErrors;
    }

    getErrors(newPassword) {
        let newErrors = [];

        if (!/[a-z]+/.test(newPassword)) {
            newErrors.push("Password must contain at least one lowercase letter");
        }

        if (!/[A-Z]+/.test(newPassword)) {
            newErrors.push("Password must contain at least one uppercase letter");
        }

        if (!/[0-9]+/.test(newPassword)) {
            newErrors.push("Password must contain at least one number");
        }

        if (!/[\"@\\|!#$%&/()=?{}.\-;'<>_,*]+/.test(newPassword)) {
            newErrors.push("Password must contain at least one special letter (from \"@\\|!#$%&/()=?{}.-;'<>_,*)");
        }

        if (newPassword.length > 0 && newPassword.length < 8) {

            newErrors.push("Your password must be a least 8 characters long.");
        }

        if (/\ /.test(newPassword)){
            newErrors.push("Password cannot contain spaces");
        }

        return newErrors;
    }

    isPasswordValid() {
        let retVal = true;

        if (!/[a-z]+/.test(this.state.password)) {
            retVal = false;
        }

        if (!/[A-Z]+/.test(this.state.password)) {
            retVal = false;
        }

        if (!/[0-9]+/.test(this.state.password)) {
            retVal = false;
        }

        if (!/[\"@\\|!#$%&/()=?{}.\-;'<>_,*]+/.test(this.state.password)) {
            retVal = false;
        }

        if(this.state.password.length > 0 && this.state.password.length < 8) {
            retVal = false;
        }

        if (/\ /.test(this.state.password)){
            retVal = false;
        }

        return retVal;
    }

    isConfirmPasswordValid() {
        if(this.isPasswordValid()){
            if(this.state.password === this.state.confirmPassword) {
                return true;
            }
        }
        return false;
    }

    isMFAValid() {
        if (this.state.userInfo.mfaMode == "None") {
            return true;
        }

        var isValid = true;

        if (!this.state.userInfo.multiFactorContact || this.state.userInfo.multiFactorContact.trim().length == 0) {
            isValid = false;
        }

        if (this.state.userInfo.multiFactorContact && this.state.userInfo.mfaMode == "Email") {
            if ((this.state.userInfo.multiFactorContact.indexOf('@') <= 0) || (this.state.userInfo.multiFactorContact.split('@').length < 2)) {
                isValid = false;
            }
        } else if (this.state.userInfo.multiFactorContact && this.state.userInfo.mfaMode == "SMS") {
            if (!/(\d){3}-(\d){3}-(\d){4}/.test(this.state.userInfo.multiFactorContact)) {
                isValid = false;
            }
        }

        return this.state.userInfo.multiFactorContact && (this.state.userInfo.multiFactorContact.trim().length > 0) && isValid;
    }

    confirmedMFA() {
        return this.state.confirmingMFA && (this.state.mfaConfirm === this.state.mfaCompare);
    }

    isButtonDisabled() {
        return (!this.isConfirmPasswordValid() || this.state.password.length < 1)
    }

    render() {
        let passwordChangeRequired = localStorage.getItem("PasswordChangeRequired") == "true";

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Account Settings</h1>
                    </div>
                </div>
                {passwordChangeRequired && <div className="row">
                    <div className="col-12">
                        <span className="text-danger">Your password was recently reset. You will need to change your password before accessing other functionality.</span>
                    </div>
                </div>}
                <div className="row accountInfo">
                    <div className="col-5">
                        <h4>Organization Information</h4>
                    </div>
                    <div className="col-7">
                        <div><label>Cannabis Number:</label><span>{this.state.userInfo.cnNumber}</span></div>
                        <div><label>Name:</label><span>{this.state.userInfo.username}</span></div>
                    </div>
                </div>
                <div className="row accountInfo">
                    <div className="col-5">
                        <h4>Login Information</h4>
                    </div>
                    <div className="col-7">
                        <div><label>Account Number:</label><span>{this.state.userInfo.id}</span></div>
                        <div><label>This Session Signed On:</label><span>{this.state.userInfo.lastSignOn ? this.state.userInfo.lastSignOn.toLocaleString() : ""}</span></div>
                        <div><label>Your Previous Sign-On:</label><span>{this.state.userInfo.previousSignOn ? this.state.userInfo.previousSignOn.toLocaleString() : "" }</span></div>
                        <div><label>Recent Failed Attempts:</label><span>{this.state.userInfo.failedAttemptCount}</span></div>
                        <div><label>Password Last Changed:</label><span>{this.state.userInfo.lastChanged ? this.state.userInfo.lastChanged.toLocaleString() : ""}</span></div>
                        <div><label>Password Expires (Days):</label><span>{this.state.userInfo.expiry ? (Math.round((this.state.userInfo.expiry - (new Date())) / (1000 * 60 * 60 * 24)) + " Days") : ""}</span></div>
                    </div>
                </div>
                {this.state.confirmingMFA ?
                    <div className="row accountInfo confirmMFADialog">
                        <div>
                            <h4>Verify Multi Factor Auth</h4>
                            <br />
                            <p>A code has been sent to you - please enter it below to verify you received it. If you did not receive a code, please press cancel and verify your information</p>
                            <label>Enter your code:</label>
                            <input type="text" className="form-control" name="mfaConfirm" onChange={this.handleChanged} value={this.state.mfaConfirm} placeholder="Enter MFA Code Sent" />
                            <br />
                            <input type="submit" className="btn btn-orange" disabled={!this.confirmedMFA()} onClick={this.submitMFAUpdate} />
                            <input type="button" className="btn btn-orange" value="Cancel" onClick={this.cancelMFAVerify}></input>
                        </div>
                    </div> :
                    <form onSubmit={this.handleMFASubmit} className="mfaInfo">
                        <div className="row accountInfo">
                            <div className="col-12">
                                <h4 >Multi Factor Authentication</h4>
                            </div>
                            <div className="col-5">
                                <label className="form-control-label authTitle">Authentication Method</label>
                                <ul>
                                    {["None", "Email", "SMS"].map(a => { return <li key={a} ><label><input type="radio" value={a} name="mfaMode" onChange={this.handleMFAChanged} checked={a == this.state.userInfo.mfaMode} />{a}</label></li> })}
                                </ul>
                            </div>
                            <div className="col-7">
                                <label className="form-control-label">Email or SMS:&nbsp;</label>
                                <input
                                    value={this.state.userInfo.multiFactorContact}
                                    name="multiFactorContact"
                                    className={classNames({
                                        'form-control': true,
                                        'is-valid': this.isMFAValid(),
                                        'is-invalid': !this.isMFAValid()
                                    })}
                                    placeholder="Contact for MFA"
                                    onChange={this.handleMFAChanged}
                                    required={this.state.userInfo.mfaMode !== "None"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.mfaErrors.map(item => <div key={item}>{item}</div>)}
                                </div>
                                <button type="submit" className="btn btn-orange btn-block" disabled={!this.isMFAValid()}>Update 2FA</button>
                            </div>
                            <div className="col-12">
                            </div>
                        </div>
                    </form>}
                <div className="row">
                    <div className="col-5">
                        <h4>Change your Password</h4>
                        <ul>
                            <li>Passwords must be at least 8 characters long</li>
                            <li>It must contain at least:
                                <ul>
                                    <li>1 uppercase letter</li>
                                    <li>1 lowercase letter</li>
                                    <li>1 number</li>
                                    <li>1 special character.</li>
                                    <li>no spaces</li>
                                    <li>Not been previously used</li>
                                </ul>
                            </li>
                        </ul>                            
                    </div>
                    <div className="col-7">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="currentPassword" className="form-control-label">
                                    Current Password
                                </label>
                                <input
                                    value={this.state.currentPassword}
                                    name="currentPassword"
                                    type="password"
                                    id="currentPassword"
                                    className={classNames({
                                        'form-control': true,
                                    })}
                                    placeholder="Current Password"
                                    onChange={this.handleChanged}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Your passwords must match.
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputPassword" className="form-control-label">
                                    New Password
                                </label>
                                <input
                                    value={this.state.password}
                                    name="password"
                                    type="password"
                                    id="inputPassword"
                                    className={classNames({
                                        'form-control': true,
                                        'is-valid': this.isPasswordValid(),
                                        'is-invalid': !this.isPasswordValid()
                                    })}
                                    placeholder="Password"
                                    onChange={this.handleChanged}
                                    required
                                />
                                <div className="invalid-feedback">
                                    {this.state.errors.map(item => <div key={ item }>{item}</div>) }
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword" className="form-control-label">
                                    Confirm New Password
                                </label>
                                <input
                                    value={this.state.confirmPassword}
                                    name="confirmPassword"
                                    type="password"
                                    id="confirmPassword"
                                    className={classNames({
                                        'form-control': true,
                                        'is-valid': this.isConfirmPasswordValid(),
                                        'is-invalid': !this.isConfirmPasswordValid()
                                    })}
                                    placeholder="Confirm Password"
                                    onChange={this.handleChanged}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Your passwords must match.
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-orange btn-block" disabled={this.isButtonDisabled()}>Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(AccountSettings);
