import React from "react";

export enum USER_ROLE {
  ADMIN = "CannabisConnect",
  AGLC = "CannabisAGLC",
  DEFAULT = "Cannabis",
  NOT_SET = "-1"
}

export const getRoleLabel = (role: USER_ROLE): string => {
  switch(role) {
    case USER_ROLE.ADMIN:
      return 'ADMIN'
    case USER_ROLE.AGLC:
      return 'AGLC'
    default:
      return ''
  }
}

export const CanEdit = (role: USER_ROLE) => {
  return role !== USER_ROLE.AGLC;
};

interface RoleContextInterface {
  role: USER_ROLE;
  setRole: (name: USER_ROLE) => void;
}

const role = {
  role: USER_ROLE.DEFAULT,
  setRole: (name: USER_ROLE) => {},
};

const RoleContext = React.createContext<RoleContextInterface>(role);
RoleContext.displayName = "RoleContext";

export default RoleContext;
