import {format, parse} from 'date-fns'

export const dateFormat = 'yyyyMMdd'
export const dateFormatExport = 'yyyy/MM/dd'

export const timeFormat = 'HHmmss'
export const timeFormatExport = 'hh:mm:ss a'

export const parseDateString = (inputString: string): string => {
  return format(
    parse(inputString, dateFormat, new Date()),
    dateFormatExport,
  )
}

export const parseTimeString = (inputString: string): string => {
  return format(
    parse(inputString, timeFormat, new Date()),
    timeFormatExport,
  )
}
