import React from 'react'
import classNames from 'classnames'

import Loading from '../loading/Loading'
import { NONE_SELECTED } from '../select'

export interface SKU {
  sku: string
  description: string
	thcMin: number,
  thcMax: number,
  thcUnits: string,
  cbMin: number,
  cbMax: number,
  cbUnits: string,
  closingDate: number
}

interface Props {
  skus: SKU[]
  disabled: boolean
  loading: boolean
  done: boolean
  errorMsg: string
  selectedSKU: string
  handleSelected: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const SkuLoaderView: React.FunctionComponent<Props> = props => {
  let skuOptions = [
    <option key={NONE_SELECTED} value={NONE_SELECTED}>
      {'Select a SKU ...'}
    </option>,
  ]

  for (let i = 0; i < props.skus.length; i++) {
    skuOptions.push(
      <option key={i + 1} value={i}>
        {`${props.skus[i].sku} - ${props.skus[i].description}`}
      </option>,
    )
  }

  let label = props.disabled ? 'SKU ' : 'SKU'

  return (
    <div className="row">
      <div className="col-5">
        <div className="form-group">
          <label htmlFor="SKU">{label}</label>

          <select
            required
            name="SKU"
            className={classNames({
              'form-control': true,
              error: props.selectedSKU === '0',
              'd-none': props.loading,
            })}
            disabled={props.disabled || !props.done || props.loading}
            value={props.selectedSKU}
            onChange={props.handleSelected}>
            {skuOptions}
          </select>
          <Loading show={props.loading} />
          {props.errorMsg && (
            <span className="text-danger">{props.errorMsg}</span>
          )}
        </div>
      </div>
      <div className="col-7">{props.children}</div>
    </div>
  )
}

export default SkuLoaderView
