import React, { ReactElement } from 'react'
import Tooltip from 'rc-tooltip'

import 'rc-tooltip/assets/bootstrap.css'

interface Props {
  placement: string
  title: any
  children: ReactElement
}

const ToolTipView = (props: Props) => {
  return (
    <Tooltip
      placement={props.placement}
      trigger={['hover']}
      overlay={<span>{props.title}</span>}>
      {props.children}
    </Tooltip>
  )
}

export default ToolTipView
