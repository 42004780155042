import React from 'react'
import classNames from 'classnames';
import { saveAs } from 'file-saver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RestUtilities from '../services/Base';

interface Props {
  asn: any;
  coAFileName: string
  close: () => void  
  uploader:  (selectedFiles: FileList|null) => void
  asnStatus: string
  uploading: boolean
  header: any
}

const CoAModalView = (props: Props) => {
  const renderUploadButton = () => {
    if(props.header.orderStatus.trim().length === 0 || props.header.orderStatus.trim() === 'DRAFT') {
      return (
        <div style={{textAlign:"center"}}>
          <input onChange={(e) =>{ props.uploader(e.target.files)}} type="file" name="file2" id="file2"  accept="application/pdf"/>
          {props.uploading ? <FontAwesomeIcon icon="spinner" spin /> : <label className="hiddenInput" htmlFor="file2">
            <div style={{ padding:"5px"}} className="btn btn-orange">
              Upload New CoA
            </div>
          </label>}
        </div>
      )
    }
  }

  const getCoa = async () => {
    var downloadLink = `/api/asn/${props.asn.licensedProducer}/${props.asn.documentNumber}/${props.asn.lineID}/coa`;
    var response = await RestUtilities.get(downloadLink);    
    saveAs(response.content, props.coAFileName.trim());
  }


  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Uploaded Certificate of Analysis</h4>

          <button
            onClick={props.close}
            type="button"
            className={classNames({
              close: true
            })}>
            <span aria-hidden="true">&times;</span>
          </button>

        </div>
        <h5 style={{"padding": "10px 20px"}}>{`Current File: ${props.coAFileName}`}</h5>
        <div className="modal-body">
          <div style={{textAlign:"center", paddingBottom:"30px"}}>
              {!props.uploading && <button className="btn btn-orange" onClick={getCoa}>
                Download CoA
             </button>}
            </div>
            {renderUploadButton()}
        </div>
      </div>
    </div>
  );

}

export default CoAModalView;
