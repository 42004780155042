import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { addYears, format, parse, isValid } from 'date-fns'

import { ASNDetailGroup } from './ASNDetailGroup'
import POLoader from '../poLoader/poLoader'
import PO from '../services/PO'
import Auth from '../services/Auth'
import ASN from '../services/ASN'
import Loading from '../loading/Loading'
import Alert from '../alert/Alert'
import CanadaPhoneInput from '../phoneInput/PhoneInput'
import { USER_ROLE } from '../signin/RoleContext';

import 'react-datepicker/dist/react-datepicker.css'
import './ASNForm.scss'
import { Routes } from '../App'
import CoAUploader from '../services/CoAUpload'
import AsnDialog from '../asnDialog/AsnDialog'

const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const dateFormat = 'yyyyMMdd'
const timeFormat = 'HHmmss'

export class ASNForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
        selectedPO: '0',
        POLineItems: [],
        header: {
            ETA: null,
            email: '',
            contactPerson: '',
            phoneNumber: '',
            reference: '',
            freightForwarded: '',
            asnNumber: ''
        },
        asnDetailItems: {},
        submitting: false,
        submitted: false,
        fetchingPOLineItems: false,
        fetchingPOLineItemError: false,
        globalCoAList: [],
        alertText: "",
        alertType: "",
        showAlert: false,
        closingDate: null
    }

    this.state.header.email = Auth.getEmail()

    this.handlePOSelected = this.handlePOSelected.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputField = this.handleInputField.bind(this)
    this.handleASNDetailChange = this.handleASNDetailChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this)

    this.isValid = this.isValid.bind(this)
    this.addLotCode = this.addLotCode.bind(this)
    this.removeLotCode = this.removeLotCode.bind(this)
    this.showAlert = this.showAlert.bind(this)
    this.dismissAlert = this.dismissAlert.bind(this)
    this.handleDraft = this.handleDraft.bind(this)
    this.handleCOASave = this.handleCOASave.bind(this);
    
  }
  componentDidMount() {
    this.getAndSetASNData()
  }

  getAndSetASNData() {
    this.props.getASN(this.props.lp).then((response) => {
      return this.loadASNData(response);
    });
  }
  onCoAUpdate = (successfulUpdate) => {
    this.props.getASN(this.props.lp).then((response) => {      
      return this.loadASNData(response)
    });
  }

  addToGlobalCoAList = (newCoAs) => {
    const originalCoAList= this.state.globalCoAList
    const concatinatedList = originalCoAList.concat(newCoAs)
    this.setState({
      globalCoAList: concatinatedList
    })

  }

  submitAllCoAs = () => {
    const coAList = this.state.globalCoAList;
    return Promise.all(coAList.map((coa) => {
      return CoAUploader.sendCoA(coa);
    })).catch((err) => {
      console.log(err)
    });
  }

  /**
   * Parse the response, format the data, and populate the read-only form.
   * @param {*} response
   */
  loadASNData(response) {
    let value = response.content.value
    if (value) {
      let selectedPO = String(value.header.originalOrderNumber)
      return this.fetchPOLineItems(selectedPO).then(() => {
        this.populateExistingASN(value)
      })
    }
  }
  /**
   * Populate the state with the given ASN data.
   * @param {*} value
   */
  populateExistingASN(value) {
    let header = value.header

    let emails = ''
    for (var i = 0; i < value.emails.length; i++) {
      emails += value.emails[i].emailAddress.trim()
      if (i < value.emails.length - 1) {
        emails += ', '
      }
    }

    header.email = emails

    header.ETA = parse(String(value.header.etaDate), dateFormat, new Date())

    let asnDetailItems = {}
    for (var i = 0; i < value.details.length; i++) {
      let item = value.details[i]
      if (!asnDetailItems[item.sku]) {
        asnDetailItems[item.sku] = []
      }      
      asnDetailItems[item.sku].push(item)
    }

    this.setState((prevState) => {
      return {
        header: header,
        selectedPO: value.header.originalOrderNumber,
        asnDetailItems: Object.assign(prevState.asnDetailItems, asnDetailItems),
      }
    })
  }
  /**
   * Handler for when the POLoader value is selected.
   *
   * @param {} POid
   */
  handlePOSelected(POid, closingDate) {
    if (POid === POLoader.NONE_SELECTED) {
      this.setState({
        selectedPO: POid,
        POLineItems: [],
          asnDetailItems: {},
        closingDate: null,
      })
    } else {
      this.setState(
        {
          selectedPO: POid,
          POLineItems: [],
          asnDetailItems: {},
              fetchingPOLineItems: true,
              closingDate: closingDate,
        },
        () => {
          this.fetchPOLineItems(POid)
        }
      )
    }
  }
  create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }
  /**Fetch
   * Fetch the PO's line items
   * @param {} POid
   */
  fetchPOLineItems(POid) {
    return PO.getLineItems(this.props.lp, POid).then((response) => {
      if (response.is_error) {
        return this.fetchPOLineItemError(response)
      }

      return this.fetchPOLineItemSuccess(response)
    })
  }
  /**
   *
   * @param {*} response
   */
  fetchPOLineItemSuccess(response) {
    let POLineItems = response.content.value
    this.populatePOLineItems(POLineItems)
    this.initASNLineItems(POLineItems)

    this.setState({
      fetchingPOLineItems: false,
      fetchingPOLineItemError: false,
    })
  }

  fetchPOLineItemError(response) {
    this.setState({
      fetchingPOLineItems: false,
      fetchingPOLineItemError: true,
    })
  }
  /**
   * Populate the PO Line Items in the state
   * @param {*} POLineItems
   */
  populatePOLineItems(POLineItems) {
    let lineItems = []
    if (POLineItems) {
      for (var i = 0; i < POLineItems.length; i++) {
        let lineItem = POLineItems[i]
        lineItems.push(lineItem)
      }
  
      this.setState({
        POLineItems: lineItems,
      })


    }


  }
  /**
   * Initialize the ASN Line Items given the provided PO Line items
   * @param {*} POLineItems
   */
  initASNLineItems(POLineItems) {
    let asnDetailItems = {}
    if (POLineItems) {
      for (var i = 0; i < POLineItems.length; i++) {
        let lineItem = POLineItems[i]
        asnDetailItems[lineItem.sku] = [
          {
            sku: lineItem.sku,
            assigned: this.create_UUID()
          },
        ]
      }
  
      this.setState({
        asnDetailItems: asnDetailItems,
      })

    }

  }
  create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }
  /**
   * Add a new ASNDetail item with same sku as
   * @param {*} sku
   */
  addLotCode(sku) {
    this.setState((prevState) => {
      let asnDetailItems = prevState.asnDetailItems
      asnDetailItems[sku].push({assigned: this.create_UUID()})
      return Object.assign(prevState, {
        asnDetailItems: asnDetailItems,
      })
    })
  }
  /**
   * Remove an ASNDetail item with same sku as
   * @param {*} sku
   */
  removeLotCode(sku, index) {
    this.setState((prevState) => {
      let asnDetailItems = prevState.asnDetailItems
      asnDetailItems[sku].splice(index, 1)
      return Object.assign(prevState, {
        asnDetailItems: asnDetailItems,
      })
    })
  }
  handleInputField(event) {
    this.handleHeaderField(event.target.name, event.target.value)
  }
  handleDateChange(date) {
    this.handleHeaderField('ETA', date)
  }
  handlePhoneNumberChange(phoneNumber) {
    this.handleHeaderField('phoneNumber', phoneNumber)
  }
  handleHeaderField(name, value) {
    this.setState((prevState) => {
      let header = {}
      header[name] = value
      return {
        header: Object.assign(prevState.header, header),
      }
    })
  }
  /**
   * Manage the state of the ASNDetail child components.
   *
   * @param {*} sku
   * @param {*} name
   * @param {*} value
   */
  handleASNDetailChange(po, index, name, value) {
    this.setState((prevState) => {
      let asnDetailItemsList = prevState.asnDetailItems

      // Ensure quantity of all items under same sku do not exceed the quantity on order
      if (name === 'openQuantity') {
        if (
          !ASNDetailGroup.isSkuQuantityValid(
            po,
            asnDetailItemsList[po.sku],
            index,
            value,
            false
          )
        ) {
          return
        }
      }

      asnDetailItemsList[po.sku][index][name] = value
      return {
        asnDetailItems: asnDetailItemsList,
      }
    })
    }
    preOpen = () => {
        this.dismissAlert();
        if (!isValid(this.state.header.ETA)) {
            this.showAlert("Must enter a valid Estimated Date of Arrival.", 'danger');
            return false;
        }
        return true;
    }
  handleCOASave = () =>  {
      this.dismissAlert();
      if (Auth.getRole() !== USER_ROLE.ADMIN && (this.props.disabled || !this.isValidMinusCOA())) {
        return
      }

      return new Promise((resolve, reject) => {
        this.coaSave(this.props.lp).then(response => {
            resolve(response);
        });
      });      
  }
  /**
   * Handles the Save Draft button pressed event.
   * @param {*} event
   */
  handleDraft(event) {
    event.preventDefault()

    this.dismissAlert()
      if (Auth.getRole() !== USER_ROLE.ADMIN && (this.props.disabled || !this.isValidMinusCOA())) {
      return
    }

    this.setState({ submitting: true }, () => {
      this.submit(this.props.lp, ASN.Status.draft)
    })
  }
  /**
   * Handles the submit button pressed event.
   * @param {*} event
   */
  handleSubmit(event) {
    event.preventDefault()

    this.dismissAlert()
      if (Auth.getRole() !== USER_ROLE.ADMIN && (this.props.disabled || !this.isValidMinusCOA())) {
      return
    }
    this.setState({ submitting: true }, () => {
      this.submit(this.props.lp, ASN.Status.submitted)
    })
  }
  createSaveData() {
    let eta = format(this.state.header.ETA, dateFormat)
    let data = {
        header: Object.assign(this.state.header, {
            originalOrderNumber: this.state.selectedPO,
            etaDate: eta,
            etaTime: '000000',
        }),
        details: [],
        emails: [],
    }

    for (let sku in this.state.asnDetailItems) {
        for (let i = 0; i < this.state.asnDetailItems[sku].length; i++) {
            let item = this.state.asnDetailItems[sku][i]
            if (!this.isASNDetailItemEmpty(item)) {
                item.sku = sku
                data.details.push(item)
            }
        }
    }

    let emails = this.state.header.email.split(',')
    emails.forEach((email) => {
        data.emails.push({
            emailAddress: email,
        })
    });

    return data;
  }
  /**
   * Submits the form data.
   * @param {*} type
   */
  submit(lp, type) {
    let data = this.createSaveData();

    let requestType =
      type === ASN.Status.draft
        ? this.props.handleDraft
        : this.props.handleSubmit

    requestType(lp, data).then((response) => {
      if (response.is_error) {
        this.setState({ submitting: false, submitted: false })
        this.showAlert(response.error_content, 'danger')
        return
      }

      this.setState({ submitting: false, submitted: true })
      this.showAlert('Success!', 'success')

      setTimeout(() => {
        this.props.history.push(Routes.Home)
      }, 3000)
    })
  }

  coaSave (lp) {
    let saveData = this.createSaveData();
    return this.props.handleDraft(lp, saveData).then((response) => {
      if(response.is_error) {
        return { error: true };
      }
      return { error: false, documentNumber: response.content.header.documentNumber, costCenter: response.content.header.costCenter, details: response.content.details };
    });
  }

  
  showAlert(text, type) {
    this.setState({ showAlert: true, alertText: text, alertType: type })
  }
  dismissAlert() {
    this.setState({ showAlert: false })
  }
  isValid() {
    return this.isHeaderValid() && this.validateASNDetailItems()
  }
  isValidMinusCOA() {
    return this.isHeaderValid() && this.validateASNDetailItems(false)
  }
  isHeaderValid() {
    if (!this.state.header.ETA || !this.isEmailValid()) {
      return false
    }
    return true
  }
  isEmailValid() {
    var emails = this.state.header.email
    if (!emails) return false
    emails = emails.split(',')
    for (var i = 0; i < emails.length; i++) {
      let email = emails[i].replace(' ', '')
      if (email == '' || !emailRegex.test(email)) {
        return false
      }
    }
    return true
  }
  isASNDetailItemEmpty(asnDetailItem) {
    return !asnDetailItem.lotCode && !asnDetailItem.openQuantity
  }
  isASNDetailItemFilled(asnDetailItem) {
    return asnDetailItem.lotCode && asnDetailItem.openQuantity
  }

  checkItemsForCoAs() {
    if (this.state.asnDetailItems !== undefined) {
      var coaCount =  0;
      const keys = Object.keys(this.state.asnDetailItems);
      for (let i = 0 ; i < keys.length; i++) {
        var currAsn =  this.state.asnDetailItems[keys[i]]
        for (let j = 0 ; j < currAsn.length; j++) {
          if (currAsn[j] !== undefined && currAsn[j].hasOwnProperty("qvUpf") && currAsn[j]["qvUpf"].trim() !== "Y") {
            return false
          } else if (currAsn[j] !== undefined && currAsn[j].hasOwnProperty("openQuantity") && currAsn[j].openQuantity > 0 && !currAsn[j].hasOwnProperty("qvUpf")) {
            return false;
          } else if (currAsn[j] !== undefined && currAsn[j].hasOwnProperty("qvUpf")) {
            coaCount++;
          }
        }
      }
      if (coaCount === 0) {        
        return false;
      }      
    }
    return true;
  }

  validateASNDetailItems(validateCoas = true) {
    if (this.state.selectedPO === '0') {
      return false
    }

    // ensure all rows are completely filled or blank.'
    let filledRows = 0    
    if (validateCoas && !this.checkItemsForCoAs()) {
      return false;
    }

    // for every sku
    for (let po of this.state.POLineItems) {      
      let sku = po.sku
      let asnDetailItemsList = this.state.asnDetailItems[sku]

      if (!asnDetailItemsList) continue

      if (
        !ASNDetailGroup.isSkuQuantityValid(po, asnDetailItemsList, -1, 0, true, true)
      ) {
        return false
      }

      // for every lot code
      for (let i = 0; i < asnDetailItemsList.length; i++) {
        // all filled
        if (this.isASNDetailItemFilled(asnDetailItemsList[i])) {
          filledRows++
          
          // if (this.asnDetailItemsList[i]?.onCoAChange === true) {
          //   return false
          // }
        }

        // not all blank
        else if (!this.isASNDetailItemEmpty(asnDetailItemsList[i])) {
          return false
        }
      }
    }

    // ensure at least one row is filled
    if (filledRows === 0) {
      return false
    }

    return true;  
  }

  render() {
    let POLineItems = []
    this.state.POLineItems.forEach((item) => {
      if (this.state.asnDetailItems[item.sku])
        POLineItems.push(
          <ASNDetailGroup
                key={item.sku}
                po={item}
                header={this.state.header}
                asn={this.state.asnDetailItems[item.sku]} //
                onChange={this.handleASNDetailChange}
                readOnly={this.props.disabled}
                submitting={this.state.submitting || this.state.submitted}
                addLotCode={this.addLotCode}
                removeLotCode={this.removeLotCode}
                lp={this.props.lp}
                saveAsn={this.handleCOASave}
                history={this.props.history}
                onCoAUpdate={this.onCoAUpdate}
                preOpen={this.preOpen}
          />
        )
    })

    let title = this.state.header.documentNumber
      ? 'ASN ' +
        this.state.header.documentNumber +
        ' - ' +
        this.state.header.orderStatus
      : 'Create ASN'

    let totalCases = 0
    for (let sku in this.state.asnDetailItems) {
      this.state.asnDetailItems[sku].forEach((asnDetailItem) => {
        if (asnDetailItem.openQuantity) {
          totalCases += parseInt(asnDetailItem.openQuantity)
        }
      })
    }

    let userIsAdmin = Auth.getRole() === USER_ROLE.ADMIN;

    let buttonDisabled =
        (!this.isValidMinusCOA() ||
        this.state.submitting ||
        this.state.submitted ||
        this.props.disabled);
    let inputDisabled =
      this.props.disabled || this.state.submitting || this.state.submitted

    return (
      <div id="create_asn">
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="row">
            <div className="col-12">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="row">
                    <div className="col-12">
                        {(this.state.header.asnNumber && this.state.header.asnNumber.trim().length > 0) ? ('ASN #' + this.state.header.asnNumber) : ''}
              </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h4>Shipment Information</h4>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-5">
              <div className="form-group">
                <label htmlFor="ETA">Estimated Date of Arrival</label>
                <DatePicker
                  className={classNames({
                    'form-control': true,
                    error: !this.state.header.ETA,
                  })}
                  required={true}
                  name="ETA"
                  selected={this.state.header.ETA}
                  onChange={this.handleDateChange}
                  dateFormat="eeee, dd MMMM yyyy"
                  disabled={inputDisabled}
                  minDate={new Date()}
                  maxDate={addYears(new Date(), 1)}
                  autoComplete={'off'}
                />
                <span className="text-danger"></span>
              </div>
            </div>
            <div className="col-5">
              <div className="form-group">
                <label htmlFor="Email">Notification Email Address(es)</label>
                <input
                  required
                  type="text"
                  value={this.state.header.email}
                  name="email"
                  className={classNames({
                    'form-control': true,
                    error: !this.isEmailValid(),
                  })}
                  disabled={inputDisabled}
                  onChange={this.handleInputField}
                />
                <span className="text-danger"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="Notes">Comments or Notes</label>
                <textarea
                  name="reference"
                  value={this.state.header.reference}
                  className="form-control"
                  disabled={inputDisabled}
                  onChange={this.handleInputField}
                  maxLength="80"
                />
                <span className="text-danger"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h4>Trucking Company Information</h4>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-5">
              <div className="form-group">
                <label htmlFor="CompanyName">Company Name</label>
                <input
                  type="text"
                  maxLength="40"
                  name="freightForwarded"
                  value={this.state.header.freightForwarded}
                  disabled={inputDisabled}
                  className="form-control"
                  onChange={this.handleInputField}
                />
                <span className="text-danger"></span>
              </div>
            </div>
            <div className="col-5">
              <div className="form-group">
                <label htmlFor="Contact">Contact Person</label>
                <input
                  type="text"
                  maxLength="50"
                  value={this.state.header.contactPerson}
                  disabled={inputDisabled}
                  name="contactPerson"
                  className="form-control"
                  onChange={this.handleInputField}
                />
                <span className="text-danger"></span>
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-5">
              <div className="form-group">
                <label htmlFor="phoneNumber">Contact Phone</label>
                <CanadaPhoneInput
                  type="text"
                  value={this.state.header.phoneNumber}
                  disabled={inputDisabled}
                  name="phoneNumber"
                  onChange={this.handlePhoneNumberChange}
                />
                <span className="text-danger"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>

          <POLoader
            disabled={this.state.submitting || this.state.submitted}
            handleSelectedPO={this.handlePOSelected}
            default={this.props.poId}
          />
                <div style={{ display: this.state.closingDate ? "block" : "none" }}>
                    If this PO will not be delivered in full before <span style={{ fontWeight: 'bold' }}>{this.state.closingDate ? this.state.closingDate.toLocaleString('default', { month: 'short' }) + " " + this.state.closingDate.getDate() : ''}</span>, you must notify your AGLC Merchandising Planner.
        </div>
          <div
            id="PO"
            className={classNames({
              empty: this.state.selectedPO === '0',
            })}
          >
            <div className="empty_msg">
              <h3>Select a PO# to continue</h3>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th className="releaseDate">
                        Lot Number - Packaged Date
                      </th>
                      {/*<th></th>*/}
                      <th>Unit(s) Per Case</th>
                      <th style={{ width: '150px' }}># of Units</th>
                      <th>Outstanding</th>
                      <th
                        className={classNames({
                          'd-none': this.props.disabled,
                        })}
                      ></th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th className="labelTotal" colSpan="4">
                        Total
                      </th>
                      <th className="total">
                        <input
                          className="form-control"
                          value={totalCases}
                          disabled={true}
                        />
                      </th>
                      <th colSpan={this.props.disabled ? 1 : 2}></th>
                    </tr>
                  </tfoot>
                  {POLineItems}
                  {this.state.fetchingPOLineItemError &&
                    !this.state.fetchingPOLineItems && (
                      <div
                        style={{
                          color: 'red',
                          padding: '25px',
                        }}
                      >
                        There was an error fetching Line Items for this PO
                      </div>
                    )}
                </table>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              row: true,
              'd-none': !userIsAdmin && buttonDisabled,
            })}
          >
            <div id="asn_submit" className="col-12">
              <Loading show={this.state.submitting} />
              <button
                type="button"
                className={classNames({ btn: true, 'btn-orange': true, 'd-none': this.props.disabled })}
                onClick={this.handleDraft}
                disabled={buttonDisabled}
              >
                + Save Draft ASN
              </button>
              <span>
                <span style={{ padding: '5px' }}>&nbsp;</span>
                <button
                  id="submit"
                  type="submit"
                  // onClick={this.submitFinal}
                  className="btn btn-orange"
                  disabled={buttonDisabled}
                >
                  + Submit ASN
                </button>
              </span>
            </div>
          </div>
        </form>

        <Alert
          text={this.state.alertText}
          type={this.state.alertType}
          close={this.dismissAlert}
          show={this.state.showAlert}
        />
      </div>
    )
  }
}

export default withRouter(ASNForm)
