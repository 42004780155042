import React from 'react'
import './ContactInfo.scss'

const ContactInfo = () => {

  return (
    <div className="ContactInfo">
      <div className="contact-table">
        <div className="contact-group">
          <div>
            <h3>Receiving Inquiries</h3>
            <div>Portal Troubleshooting</div>
            <div>Appointment Booking Issues</div>
          </div>
          <div>
            <div>Phone: 1-825-402-6858</div>
            <div>Email: <a href="mailto:CannabisReceiving@Connect-Logistics.com">CannabisReceiving@Connect-Logistics.com</a></div>
          </div>
        </div>      
        <div className="contact-group">
        <div>
            <h3>Customer Service</h3>
            <div>Portal Account Creations</div>            
          </div>
          <div>
            <div>Phone: 1-825-202-2000</div>
            <div>Email: <a href="mailto:CannabisCS@Connect-Logistics.com">CannabisCS@Connect-Logistics.com</a></div>
          </div>
        </div>
        <div className="contact-group">
        <div>
            <h3>Barcode Approvals</h3>
            <div>Barcode Verifications</div>            
          </div>
          <div>            
            <div>Email: <a href="mailto:CannabisBarcodes@Connect-Logistics.com">CannabisBarcodes@Connect-Logistics.com</a></div>
          </div>
        </div>
        <div className="contact-group">
        <div>
            <h3>Value Added Services</h3>
            <div>General Inventory Inquiries</div>
            <div>Value Added Services Inquiries</div>
          </div>
          <div>            
            <div>Email: <a href="mailto:Cnbsvas@Connect-Logistics.com">Cnbsvas@Connect-Logistics.com</a></div>
          </div>
        </div>
        <div className="contact-group">
          <div className="center-text">
            <h3>Hours of Operation</h3>
            <div>Monday - Friday 8:00 am - 4:30 pm Mountain Time</div>
            <div>Closed Saturday, Sunday, and Statutory Holidays</div>
          </div>          
        </div>
      </div>
    </div>
  );
}

export default ContactInfo
