import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'

import NavHeaderLayout from './NavHeaderLayout'
import Auth from '../services/Auth'
import './NavHeader.scss'
import RoleContext, { getRoleLabel } from '../signin/RoleContext'
import { Routes } from '../App'
import ActionRequestNotification from '../actionRequired/ActionRequestNotification'

//import SessionExpiryModal from '../SessionExpiryModal';



const NavHeader = () => {
  const [loggedOut, setLoggedOut] = useState(false)

  const getWelcomeMessage = () => {
    let msg = ''
    const firstName = Auth.getFirstName()
    if (firstName) {
      msg = 'Welcome back, ' + firstName
    } else {
      msg = 'Hello!'
    }
    return msg
  }

  const getInitials = () => {
    let initials = ''
    const firstName = Auth.getFirstName()
    const lastName = Auth.getLastName()

    if (firstName) {
      initials += firstName.charAt(0)
    }
    if (lastName) {
      initials += lastName.charAt(0)
    }

    return initials
  }

  const signOut = () => {
    setLoggedOut(true)
  }

  if (loggedOut) {
    return <Redirect to={Routes.SignOut} />
  }

  return (
    <div>
      <ActionRequestNotification mode="PasswordExpiry" />
      {/*<SessionExpiryModal /> */}
      <RoleContext.Consumer>
        {({role}) => {
          return (
            <NavHeaderLayout
              message={getWelcomeMessage()}
              initials={getInitials()}
              onSignout={signOut}
              userRole={getRoleLabel(role)}
            />
          )
        }}
      </RoleContext.Consumer>
    </div>
  )
}

export default NavHeader
