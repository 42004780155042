import React, { FunctionComponent } from 'react'
import Modal from 'react-modal'

import './Modal.scss'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(100, 100, 100, 0.75)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0px solid transparent',
    padding: 0,
  },
}

interface Props {
  isOpen: boolean
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== 'test')
  Modal.setAppElement('#root')

const GenericModal: FunctionComponent<Props> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      style={customStyles}
      shouldCloseOnOverlayClick={false}>
      { props.children }
    </Modal>
  )
}

export default GenericModal
