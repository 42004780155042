import React, { Component } from 'react'
import ASNForm from './ASNForm'
import ASN from '../services/ASN'

export class ASNFormCreate extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDraft = this.handleDraft.bind(this)

    this.getASN = this.getASN.bind(this)
  }
  handleSubmit(lp, data) {
    return ASN.createSubmit(lp, data)
  }
  handleDraft(lp, data) {
    return ASN.createDraft(lp, data)
  }
  getASN(lp) {
    // dummy function, we are creating a new ASN
    return new Promise((resolve, reject) => {
      resolve({
        content: {
          value: null,
        },
      })
    })
  }
  render() {
    return (
      <ASNForm
        disabled={false}
        handleSubmit={this.handleSubmit}
        handleDraft={this.handleDraft}
        getASN={this.getASN}
        lp={this.props.match.params.LPid}
      />
    )
  }
}
