import React from 'react'
import {Link} from 'react-router-dom'
import { Routes } from '../App'

interface Props {
  message: string
  initials: string
  userRole: string
  onSignout: () => void
}

const NavHeaderLayout = (props: Props) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to={Routes.Home}></Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarText">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active"></li>
        </ul>
        <span className="navbar-text">
          <div className="info">
            <div className="welcome">{props.message}</div>
            <div>
              {props.userRole && (
                <span className="btn btn-link" style={{color: 'black'}}>
                  {`Signed in as: ${props.userRole.toUpperCase()}`}
                </span>
              )}
              {props.userRole && <span className="divider">|</span>}
              <Link className="btn btn-link" to={Routes.AccountSettings}>
                Account Settings
              </Link>
              <span className="divider">|</span>
              <Link className="btn btn-link" to={Routes.Contact}>
                Contact Us
              </Link>
              <span className="divider">|</span>
              <Link className="btn btn-link" to={Routes.SignOut}>
                Logout
              </Link>
            </div>
          </div>
          <div className="circle">
            <div>{props.initials}</div>
          </div>
        </span>
      </div>
    </nav>
  )
}

export default NavHeaderLayout
