import React, { useState, useEffect } from 'react'
import Alert from '../alert/Alert';
import { useHistory } from 'react-router-dom';

interface Props {    
    
}

const useTimeout = () => {
    let history = useHistory();

    const idleCountKey = 'IdleCount';
    localStorage.setItem(idleCountKey, (0 as any));

    let [displayWarning, setDisplayWarning] = useState(false);	

    function resetTimeOut() {
        localStorage.setItem(idleCountKey, (0 as any));
        setDisplayWarning(false);
    }

    function incrementTimeOut() {
        let currentCount = parseInt(localStorage.getItem(idleCountKey) as string);
        currentCount += 1;
        if (currentCount > 29) {
            history.push('/signout');
        } else if (currentCount > 24) {
            setDisplayWarning(true);
        }
        localStorage.setItem(idleCountKey, (currentCount as any));
    }

    useEffect(() => {
        document.addEventListener('mousemove', resetTimeOut);  
	    setInterval(incrementTimeOut, 60000);
    }, []);

    return displayWarning;
}

const Timeout = (props: Props) => {
    let displayWarning = useTimeout();

    return (
        <>
            {displayWarning &&
                <Alert
					text={"You have been inactive for 25 minutes. If no activity is detected within the next 5 minutes your session will be ended."}
					type={'danger'}
            />}
		</>
    )
}

export default Timeout
