import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Auth from '../services/Auth'
import { Routes } from '../App'

interface Props {
  mode: string
}

const ActionRequestNotification = (props: Props) => {
  const [daysToExpiry, setDaysToExpiry] = React.useState(999)
  const [type, setType] = React.useState("")
  const [text, setText] = React.useState("")
  const [actionLinkText, setActionLinkText] = React.useState("")
  const [actionLinkUrl, setActionLinkUrl] = React.useState("")
  const [cookieSet, setCookieSet] = React.useState(false)
  const threshold = 15;

  React.useEffect(() => {
    switch (props.mode) {
      case "PasswordExpiry":
        Auth.getLoggedInUserInfo().then((result) => {
          if (result && result.expiry) {
            var currDay = new Date();
            const diffTime = Number(result.expiry) - Number(currDay);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            setDaysToExpiry(diffDays);
            setType("warning");
            setText(`Your password will expire in ${diffDays} days`);
            setActionLinkText("Please reset your password here");
            setActionLinkUrl(Routes.AccountSettings);


            var cookies = document.cookie;
            setCookieSet(cookies.indexOf("CC_Hide_PwdNotification=true") >= 0);

          }
        });

        break;
    }
  }, [props.mode]);

  var setCookie = () => {
    var cookieExpiry = new Date();
    cookieExpiry.setDate(cookieExpiry.getDate() + 1);
    document.cookie = "CC_Hide_PwdNotification=true; expires=" + cookieExpiry.toUTCString() + ";path=/";
    setCookieSet(true);
  }

  return (
    <div style={{ width: "100%" }}
      className={
        classNames({
          alert: true,
          ['alert-' + type]: true,
          'alert-dismissable': true,
          'fade': true,
          show: !cookieSet && (daysToExpiry < threshold)
        })
      }
      role="alert">
      {text}&nbsp;
      {actionLinkText && actionLinkUrl && <Link to={actionLinkUrl}>{actionLinkText}</Link>}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={setCookie}>
        <FontAwesomeIcon icon="times" />
      </button>
    </div>
  )
}

export default ActionRequestNotification
