const KEYS = {
  token: 'token',
}

interface AuthResponse {
  token: string
}

export default class Auth {
  static setResponse(response: AuthResponse) {
    window.localStorage.setItem(KEYS.token, response.token)
  }

  static clear() {
    window.localStorage.clear()
  }

  static getToken() {
    return window.localStorage.getItem(KEYS.token)
  }
}
