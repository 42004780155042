import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Saving = props => {
  return (
    <div style={{textAlign:"center"}}>

        <div>
            <FontAwesomeIcon icon="spinner" spin />
        </div>
        <div>
            Saving
        </div>
      
    </div>
  )
}

export default Saving
