import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { parseDateString, parseTimeString } from '../dates'
import ASN from '../services/ASN'
import './AsnList.scss'

import ToolTipView from '../toolTipView/ToolTipView'
import AsnDialogOpener from '../asnDialog/AsnDialogOpener'
import { MODAL_TYPE } from '../asnDialog/Context'
import { USER_ROLE, CanEdit } from '../signin/RoleContext'

interface Props {
  asn: any //TODO: properly define asn
  userRole: USER_ROLE
}

const ASNListItemView = (props: Props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  let createdTime = props.asn.createdTime
  let po = props.asn.originalOrderNumber.trim()

  let amendAsn = async () => {
    setLoading(true);
    if (ASN.isDraft(props.asn.orderStatus)) {
      history.push(`/asn/amend/${props.asn.licensedProducer}/${po}/${props.asn.documentNumber}`);
    } else {
      let amendAsn = await ASN.startAmend(props.asn.licensedProducer, po, props.asn.documentNumber);      
      history.push(`/asn/amend/${props.asn.licensedProducer}/${po}/${amendAsn.content.header.documentNumber}`)
    }
  }

  if (createdTime < 100000) {
    createdTime = '0' + createdTime
  }
  return (
    <>
      <div>{props.asn.documentNumber}</div>
      <div>{props.asn.orderStatus}</div>
      <div>{po}</div>
      <div>{props.asn.asnNumber}</div>
      <div>
        {parseDateString(props.asn.createdDate)}
        <br />
        {parseTimeString(createdTime)}
      </div>
      <div>{parseDateString(props.asn.etaDate)}</div>
      <div>{props.asn.quantity}</div>
      <div className="actions" id={'actions_' + props.asn.documentNumber}>
        <div>
          <ToolTipView placement="top" title="View the ASN">
            <Link
              className="btn btn-orange view"
              to={`/asn/view/${props.asn.licensedProducer}/${po}/${props.asn.documentNumber}`}
            >
              <FontAwesomeIcon icon="file-alt" />
            </Link>
          </ToolTipView>
          {CanEdit(props.userRole) && (
            <ToolTipView
              placement="top"
              title={
                ASN.isDraft(props.asn.orderStatus)
                  ? 'Edit Draft ASN'
                  : 'Amend the ASN'
              }
            >
              {loading ? <button className={classNames({
                  edit: true,
                  btn: true,
                  'btn-orange': true
                })}><FontAwesomeIcon icon="spinner" spin /></button> : <button
                className={classNames({
                  edit: true,
                  btn: true,
                  'btn-orange': true,
                  'd-none': !ASN.canAmend(props.asn.orderStatus),
                })}
                onClick={amendAsn}                
              >
                <FontAwesomeIcon icon="edit" />
              </button>}
            </ToolTipView>
          )}

          {CanEdit(props.userRole) && (
            <ToolTipView placement="top" title="Cancel the ASN">
              <span>
                {/* wrap in div so that tooltip still works */}
                <AsnDialogOpener
                  asn={props.asn}
                  mode={MODAL_TYPE.CANCEL}
                  className={classNames({
                    cancel: true,
                    btn: true,
                    'btn-orange': true,
                    'd-none': !ASN.canCancel(props.asn.orderStatus),
                  })}
                >
                  <FontAwesomeIcon icon="times" />
                </AsnDialogOpener>
              </span>
            </ToolTipView>
          )}
          {CanEdit(props.userRole) && (
            <ToolTipView placement="top" title="Remove the ASN">
              <span>
                {/* wrap in div so that tooltip still works */}
                <AsnDialogOpener
                  asn={props.asn}
                  mode={MODAL_TYPE.REMOVE}
                  className={classNames({
                    remove: true,
                    btn: true,
                    'btn-orange': true,
                    'd-none': !ASN.canRemove(props.asn.orderStatus),
                  })}
                >
                  <FontAwesomeIcon icon="trash-alt" />
                </AsnDialogOpener>
              </span>
            </ToolTipView>
          )}
        </div>
      </div>
    </>
  )
}

export default ASNListItemView