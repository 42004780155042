import React from 'react'
import LotCodeModalContext from './context'

interface Props {
  lotCode?: any
  disabled: boolean
  type: number
}

const LotCodeModalOpener: React.FunctionComponent<Props> = (props) => {
  return (
    <LotCodeModalContext.Consumer>
      {({open, setLotCode, setMode}) => {
        const onClick = () => {
          setLotCode(props.lotCode)
          setMode(props.type)
          open()
        }

        return (
          <button disabled={props.disabled} className='btn btn-orange' onClick={onClick}>
            { props.children }
          </button>
        )
      }}
    </LotCodeModalContext.Consumer>
  )
}

export default LotCodeModalOpener
