import React, { Component, useState } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import classNames from 'classnames'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner,
  faTrashAlt,
  faTimes,
  faEdit,
  faFileAlt,
  faCopy,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'

import { DefaultLayout } from './Layout'
import ContactInfo from './contactInfo/ContactInfo';
import SignIn from './signin/SignIn'
import ASNList from './asnList/ASNList'
import LotCodeManager from './lotCodeManager/lotCodeManager'

import { ASNFormCreate } from './asnForm/ASNFormCreate'
import { ASNFormAmend } from './asnForm/ASNFormAmend'
import { ASNFormView } from './asnForm/ASNFormView'
import AccountSettings from './settings/AccountSettings'
import RoleContext, { USER_ROLE } from './signin/RoleContext'

import LPContext, { DEFAULT_LP } from './lpLoader/LPContext'
import SignOut from './signout/SignOut'
import NavHeader from './header/NavHeader'
import Auth from './services/Auth'
import LPLoader from './lpLoader/lpLoader'
import Timeout from './timeout/timeout'
//import Test from './_test/Test'
//import NoRoute from './noRoute/noRoute'

ReactGA.initialize('UA-153581572-1')
library.add(faSpinner, faTrashAlt, faTimes, faEdit, faFileAlt, faCopy, faUpload)

const usePageViews = () => {
  let location = useLocation()
  React.useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])
}

export const Routes = {
  SignIn: '/signin',
  SignOut: '/signout',
  Contact: '/contact',
  AccountSettings: '/settings',

  Home: '/asn',
  LotCode: '/asn/:LPid/lotCodes',
  AsnCreate: '/asn/create/:LPid/',
  AsnView: '/asn/view/:LPid/:POid/:DocumentNumber',
  AsnAmend: '/asn/amend/:LPid/:POid/:DocumentNumber',
}

const App = () => {
  usePageViews()

  const [role, setRole] = useState<USER_ROLE>(USER_ROLE.NOT_SET)
  const [LP, setLP] = useState(DEFAULT_LP)

  // if no token exists(ie. logged out), reset role and LP
  let token = Auth.getToken()
  if(!token && role !== USER_ROLE.NOT_SET) {
    setRole(USER_ROLE.NOT_SET)
    setLP(DEFAULT_LP)
  }

  // get user role and LP if not already in context
  if (role === USER_ROLE.NOT_SET) {
    if (token) {
      let roleFromToken = Auth.getRole(token)
      if (
        roleFromToken === USER_ROLE.ADMIN ||
        roleFromToken === USER_ROLE.AGLC ||
        roleFromToken === USER_ROLE.DEFAULT
      ) {
        setRole(roleFromToken)
      }

      if (roleFromToken === USER_ROLE.DEFAULT) {
        setLP(Auth.getLP(token))
      } 
    }
  }

  let requiresLPLoader = (role === USER_ROLE.ADMIN || role === USER_ROLE.AGLC);

  let passwordChangeRequired = localStorage.getItem("PasswordChangeRequired") == 'true';

  return (
      <RoleContext.Provider
        value={{
          role: role,
          setRole: setRole,
        }}
      >
        <LPContext.Provider
          value={{
            LP: LP,
            setLP: setLP,
          }}
        >
          {/* If not authenticated, all routes go to sign in page */}
          {!Auth.isSignedInIn() && (
            <Switch>
              <Route path={Routes.SignOut} component={SignOut} />
              <Route>
              <DefaultLayout>
                  <Route path={'*'} component={SignIn} />
                </DefaultLayout>
              </Route>
            </Switch>
          )}        

          {Auth.isSignedInIn() && (
            <>
              <Timeout />
              <NavHeader />

              {passwordChangeRequired && (
                  <Switch>
                      <Route path={Routes.SignOut} component={SignOut} />
                      <Route>
                          <DefaultLayout>
                              <Route path={'*'} component={AccountSettings} />
                          </DefaultLayout>
                      </Route>
                  </Switch>
              )}

                {/* Routes that require an LP are prefixed with /asn/*/}
              {!passwordChangeRequired && (
                  <>
                    <Switch>
                      <Route path={'*'} children={({ match }) => (
                        requiresLPLoader && (
                          <div className={classNames({ container: true, lpContainer: true, 'd-none': !match?.url.startsWith('/asn')})}>
                            <LPLoader disabled={false} handleSelected={setLP} />
                          </div>
                        )
                      )} />
                    </Switch>

                    <Switch>

                      <Route path="/asn/">
                        <DefaultLayout>
                          <Switch>
                            <Route path={Routes.LotCode} component={LotCodeManager} />
                            <Route path={Routes.AsnCreate} component={ASNFormCreate} />
                            <Route path={Routes.AsnAmend} component={ASNFormAmend} />
                            <Route path={Routes.AsnView} component={ASNFormView} />
                            <Route exact path={Routes.Home} component={ASNList} />
                          </Switch>
                        </DefaultLayout>
                      </Route>
                      <DefaultLayout>
                        <Route
                          path={Routes.AccountSettings}
                          component={AccountSettings}
                        />
                        <Route path={Routes.SignOut} component={SignOut} />
                        <Route path={Routes.Contact} component={ContactInfo} />
                        <Route
                          exact
                          path={'/'}
                          component={() => <Redirect to={Routes.Home} />}
                        />                        
                        {/*
                        <Route path="/test" component={Test} />
                        <Route component={NoRoute} />
                        */}
                      </DefaultLayout>
                    </Switch>
                  </>
               )}
            </>
          )}
        </LPContext.Provider>
      </RoleContext.Provider>
    
  )
}

export default App
