import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Loading = props => {
  return (
    <div
      className={classNames({
        loading: true,
        'd-none': !props.show,
      })}>
      <FontAwesomeIcon icon="spinner" spin />
    </div>
  )
}

export default Loading
