import React, { Component } from 'react'

interface Props {
  selectFunction: (option: string) => void
}

export class SortingDropdown extends Component<Props> {
  onChange(e: React.FormEvent)  {
    const target = e.target as HTMLSelectElement;
    const value: string = target.value;
    this.props.selectFunction(value);
  }
  render() {
    return (
      <span>
        <label  htmlFor="sortbyDropdown">
          Sort By:
        </label>
        <select  className="form-control" onChange={(e) => this.onChange(e)} id="sortByDropdown">
          <option value="default">ASN ID</option>
          {/* <option value="id">ASN ID</option> */}
          <option value="created">ASN Created Date</option>
          <option value="eta">ASN ETA</option>
        </select>
      </span>
    );
  }
}

export default SortingDropdown
