import React, { Component } from 'react';
import Loading from '../loading/Loading'

interface Props {
  selectFunction: (option: string) => void
  setPrimaryFilterValue: (option: string) => void

  label: string
  lp?: number
  initialOption: string
  options: JSX.Element[]
}

interface State {
  loading: boolean
  selectOptions: JSX.Element[]
}

export class SecondaryASNFilterDropdown extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    super(props)
    this.state= {
      loading: true,
      selectOptions:new Array()
    }
  }

  componentDidMount() {
    this.props.selectFunction(this.props.initialOption);
  }

  onChange = (e: React.FormEvent) => {
    const target = e.target as HTMLSelectElement;
    const value: string = target.value;
    this.props.selectFunction(value);
  }

  buildElements = () => {
    return(
      <form>
      <div className="form-group">
        <label htmlFor="exampleFormControlSelect1">{`${this.props.label}`}</label>
        <select onChange={(e) => this.onChange(e)} className="form-control" id="exampleFormControlSelect1">
            {this.props.options}
        </select>
      </div>
    </form>
    )
  }

  showLoading = () => {
    return (
      <Loading show={true}/>
    );
  }

  buildDropDown = () => {
    return this.state.loading === true
      ? this.showLoading()
      : this.buildElements()
  }

  render() {
    return(
      <div>
        {this.buildElements()}
      </div>
    );
  }
}

export default SecondaryASNFilterDropdown;
