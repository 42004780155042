import RestUtilities from './Base'

export default class PO {
  static getList(lp: number) {
    return RestUtilities.get(`/api/${lp}/po/list/ids`).then(response => {
      return response
    })
  }

  static getLineItems(lp: number, POid: string) {
    return RestUtilities.get(`/api/${lp}/po/` + POid).then(response => {
      return response
    })
  }

  static createLotCode(lp: number, data: any) {
    return RestUtilities.post(`/api/${lp}/lotCodes`, data).then(response => {
      return response
    })
  }

  static editLotCode(lp: number, data: any) {
    return RestUtilities.put(`/api/${lp}/lotCodes`, data).then(response => {
      return response
    })
  }

  static getLotCodes(lp: number, sku: string) {
    return RestUtilities.get(`/api/${lp}/lotCodes/` + sku).then(response => {
      return response
    })
  }

  static getSkus(lp: number) {
    return RestUtilities.get(`/api/${lp}/skus`).then(response => {
      return response
    })
  }

}
