import React, { Component } from 'react';

import { ASNDetail } from './ASNDetail';

const numericRegex =  /^[0-9]*$/;

/**
 * Represents a group of line items which share the same SKU, but differing lot codes.
 */
export class ASNDetailGroup extends Component {
    constructor(props) {
        super(props)
        const coASet = {};

        this.exceedsQuantity = this.exceedsQuantity.bind(this)
        // this.hasCoASet =  this.hasCoASet.bind(this);
    }
    /**
     * Validate that the quantities of all asn line items for the same sku do not exceed the total
     * defined in the PO.
     *
     * Optionally pass in a new quantity value at the index of the asnDetailLineItemList to test
     * if the new value exceeds quantity limit.
     *
     * @param {*} poLineItem The SKU Line Item defined in the PO
     * @param {*} asnDetailItemsList The ASNDetail line items to verify, all under the same SKU
     * @param {*} index (optional) The index in the asnDetailItemList to replace the quantity
     * @param {*} value (optional) The value
     */
    static isSkuQuantityValid(poLineItem, asnDetailItemsList, index=-1, value=0, checkForMultiple=false) {
        let skuQuantity = 0;
        let additionalQuantity = 0;
        let maximumQuantity = poLineItem.quantityOnOrder;
        for(let i = 0; i < asnDetailItemsList.length; i++) {
            if(asnDetailItemsList[i].maximumQuantity != undefined) {
                maximumQuantity = asnDetailItemsList[i].maximumQuantity;
            }

            if(asnDetailItemsList[i].confirmedQuantity != undefined) {
                additionalQuantity += asnDetailItemsList[i].confirmedQuantity;
            }

            if(i != index) {
                let q = parseInt(asnDetailItemsList[i].openQuantity);
                if(q)
                    skuQuantity += q;
            }
        }

        if(!numericRegex.test(value)) {
            return false;
        }

        skuQuantity += parseInt(value, 10);
        if(skuQuantity > (maximumQuantity + additionalQuantity)) {
            return false
        }

        // check for proper multiple
        if(checkForMultiple && skuQuantity % poLineItem.eachesPerCase != 0) {          
            return false
        }

        return true
    }
    exceedsQuantity() {
        if(this.props.readOnly) {
            return false;
        }
        return !ASNDetailGroup.isSkuQuantityValid(this.props.po, this.props.asn);
    }







    render () {
        const coATracker = {};
        let asnDetailItems = [];
        this.props.asn.forEach((asnDetail, index) => {
            asnDetailItems.push(
                <ASNDetail
                    key={index}
                    po={this.props.po}
                    asn={asnDetail}
                    header={this.props.header}
                    onChange={this.props.onChange}
                    readOnly={this.props.readOnly}
                    submitting={this.props.submitting}
                    addLotCode={this.props.addLotCode}
                    removeLotCode={this.props.removeLotCode}
                    index={index}
                    lp={this.props.lp}
                    exceedsQuantity={this.exceedsQuantity}
                    saveAsn={this.props.saveAsn}
                    history={this.props.history}
                    onCoAUpdate={this.props.onCoAUpdate}
                    preOpen={this.props.preOpen}
                />
            );
        });
        return (
            <tbody>
                { asnDetailItems }
            </tbody>
        );
    }
}
