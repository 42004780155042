import React, { Component } from 'react'
import ASNForm from './ASNForm'
import ASN from '../services/ASN'

export class ASNFormAmend extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDraft = this.handleDraft.bind(this)

    this.getASN = this.getASN.bind(this)
  }
  handleSubmit(lp, data) {
    return ASN.amendSubmit(lp, data)
  }
  handleDraft(lp, data) {
    return ASN.amendDraft(lp, data)
  }
  create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }
  getASN(lp) {
    return ASN.get(
      lp,
      this.props.match.params.POid,
      this.props.match.params.DocumentNumber
    ).then((response) => {
      if (response && response.content && response.content.value && response.content.value.details) {
        for(let i = 0; i < response.content.value.details.length; i++) {
          response.content.value.details[i].assigned = this.create_UUID();
        }
      }

      return response
    })
  }
  render() {
    return (
      <ASNForm
        disabled={false}
        handleSubmit={this.handleSubmit}
        handleDraft={this.handleDraft}
        getASN={this.getASN}
        poId={this.props.match.params.POid}
        lp={this.props.match.params.LPid}
      />
    )
  }
}
