import React from 'react'

export enum MODAL_TYPE {
  CANCEL,
  REMOVE,
  UPLOAD
}

const modal = {
  isOpen: false,
  open: () => {},
  close: () => {},
  asn: {},
  setASN: (asn: any) => {},
  mode: MODAL_TYPE.CANCEL,
  setMode: (type: number) => {}
}

const AsnDialogContext = React.createContext(modal)
AsnDialogContext.displayName = 'AsnDialogContext'

export default AsnDialogContext
