import React, {useState} from 'react'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import {addDays, format, parse} from 'date-fns'

import {dateFormat} from '../dates'
import Loading from '../loading/Loading'
import { SKU } from '../skuLoader/skuLoaderView'

interface Props {
  mode: number
  lotCode?: any
  requesting: boolean
  completed: boolean
  error: boolean
  errorText: string
  description: string
  sku: SKU
  submit: (
    lotCodeId: string,
    harvestDate: string,
    thc: string,
    thcUnits: string,
    cbd: string,
    cbdUnits: string,
    originalLotCodeId: string | null,
    originalHarvestDate: string | null,
  ) => void
  close: () => void
}

export const MODAL_MODE = {
  ADD: 0,
  EDIT: 1,
}

export const UNITS = {
  NONE: '0',
  PERCENT: '1',
  ML: '2',
  CAPSULE: '3',
  GRAM: '4',
  MG: '5',
  GRAMSHORT: '6'
}

export const UNIT_LABELS = {
  NONE: 'Select Units',
  PERCENT: '%',
  ML: 'mg/ml',
  CAPSULE: 'mg/capsule',
  GRAM: 'mg/gram',
  MG: 'mg',
  GRAMSHORT: 'mg/g',
}

const regexTwoDecimalPlaces = /^([0-9]{0,5})(\.)?([0-9]{0,2})$/

const LotCodeModalView = (props: Props) => {
  const getUnits = (units: string) => {
    if (units === null || units === undefined) return UNITS.NONE

    let unitString = units.trim().toLowerCase()
    if (unitString.trim() === UNIT_LABELS.PERCENT) {
      return UNITS.PERCENT
    } else if (unitString.trim() === UNIT_LABELS.ML) {
      return UNITS.ML
    } else if (unitString.trim() === UNIT_LABELS.CAPSULE) {
      return UNITS.CAPSULE
    } else if (unitString.trim() === UNIT_LABELS.GRAM) {
      return UNITS.GRAM
    } else if (unitString.trim() === UNIT_LABELS.MG) {
      return UNITS.MG
    } else if (unitString.trim() === UNIT_LABELS.GRAMSHORT) {
      return UNITS.GRAMSHORT
    }

    return UNITS.NONE
  }

  const getUnitString = (units: string) => {
    if (units === UNITS.NONE) {
      return ''
    } else if (units === UNITS.PERCENT) {
      return UNIT_LABELS.PERCENT
    } else if (units === UNITS.ML) {
      return UNIT_LABELS.ML.toUpperCase()
    } else if (units === UNITS.CAPSULE) {
      return UNIT_LABELS.CAPSULE.toUpperCase()
    } else if (units === UNITS.GRAM) {
      return UNIT_LABELS.GRAM.toUpperCase()
    } else if (units === UNITS.MG) {
      return UNIT_LABELS.MG.toUpperCase()
    } else if (units === UNITS.GRAMSHORT) {
      return UNIT_LABELS.GRAMSHORT.toUpperCase()
    }

    return ''
  }

  const getDisplayUnits = (units: string) => {
    if (units === null || units === undefined) return UNITS.NONE

    let unitString = units.trim().toLowerCase()
    if (unitString.trim() === UNIT_LABELS.PERCENT) {
      return UNIT_LABELS.PERCENT
    } else if (unitString.trim() === UNIT_LABELS.ML) {
      return UNIT_LABELS.ML
    } else if (unitString.trim() === UNIT_LABELS.CAPSULE) {
      return UNIT_LABELS.CAPSULE
    } else if (unitString.trim() === UNIT_LABELS.GRAM) {
      return UNIT_LABELS.GRAM
    } else if (unitString.trim() === UNIT_LABELS.MG) {
      return UNIT_LABELS.MG
    } else if (unitString.trim() === UNIT_LABELS.GRAMSHORT) {
      return UNIT_LABELS.GRAMSHORT
    }

    return '';
  }

  let lc: any = {
    lotCodeId: '',
    harvestDate: null,
    thc: '0',
    thcUnits: UNITS.NONE,
    cbd: '0',
    cbdUnits: UNITS.NONE,
  }

  if (props.lotCode) {
    if (props.lotCode.lotCodeId) {
      lc.lotCodeId = props.lotCode.lotCodeId.trim()
    }

    if (props.lotCode.harvestDate) {
      lc.harvestDate = parse(props.lotCode.harvestDate, dateFormat, new Date())
    }

    if (props.lotCode.thc) {
      lc.thc = props.lotCode.thc.toString()
    }

    if (props.lotCode.cbd) {
      lc.cbd = props.lotCode.cbd.toString()
    }

    lc.thcUnits = getUnits(props.lotCode.thcUnits)
    lc.cbdUnits = getUnits(props.lotCode.cbdUnits)
  } else {
    lc.thcUnits = getUnits(props.sku.thcUnits)
    lc.cbdUnits = getUnits(props.sku.cbUnits)
  }

  const [lotCodeId, setLotCodeId] = useState(lc.lotCodeId)
  const [harvestDate, setHarvestDate] = useState(lc.harvestDate)
  const [thc, setThc] = useState(lc.thc)
  const [thcUnits, setThcUnits] = useState(lc.thcUnits)
  const [cbd, setCbd] = useState(lc.cbd)
  const [cbdUnits, setCbdUnits] = useState(lc.cbdUnits)
  const [cbOutOfRange, setCBOutOfRange] = useState(lc.cbd > props.sku.cbMax || lc.cbd < props.sku.cbMin);
  const [thcOutOfRange, setThcOutOfRange] = useState(lc.thc > props.sku.thcMax || lc.thc < props.sku.thcMin);

   const isFieldValid = (value: string, unit: string): boolean => {
    return (
      unit === UNITS.NONE || value.trim() !== ''
    )
  }

  const isValid = (): boolean => {
    let lotCodeFilled = lotCodeId.trim().length > 0

    return (
      lotCodeFilled &&
      harvestDate &&
      isFieldValid(thc, thcUnits) &&
      isFieldValid(cbd, cbdUnits)
    )
  }

  const isNumeric = (numeric: string): string | null => {
    const parsed = parseFloat(numeric)
    if (isNaN(parsed)) {
      return null
    }

    if (
      !regexTwoDecimalPlaces.test(numeric) ||
      parsed > 99999.99 ||
      parsed < 0
    ) {
      return null
    }
    return numeric
  }

  const updateThc = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNumeric(e.target.value) === null) {
      if (e.target.value.trim() === '') {
        setThc(e.target.value)
        setThcOutOfRange(+e.target.value > props.sku.thcMax || +e.target.value < props.sku.thcMin);
      }
      return
    }

    setThc(e.target.value)
    setThcOutOfRange(+e.target.value > props.sku.thcMax || +e.target.value < props.sku.thcMin);
  }

  const updateCbd = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNumeric(e.target.value) === null) {
      if (e.target.value.trim() === '') {
        setCbd(e.target.value)
        setCBOutOfRange(+e.target.value > props.sku.cbMax || +e.target.value < props.sku.cbMin);
      }
      return
    }
    setCbd(e.target.value);
    setCBOutOfRange(+e.target.value > props.sku.cbMax || +e.target.value < props.sku.cbMin);
  }

  const submit = () => {
    props.submit(
      lotCodeId,
      format(harvestDate, dateFormat),
      thc,
      getUnitString(thcUnits),
      cbd,
      getUnitString(cbdUnits),
      lc.lotCodeId,
      lc.harvestDate ? format(lc.harvestDate, dateFormat) : null,
    )
  }

  const showError = !props.requesting && props.completed && props.error
  const showSuccess = !props.requesting && props.completed && !props.error
  const disabled = props.requesting || showSuccess
  const areFieldsValid = isValid()

  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <div>
            <h4 className="modal-title">{'Lot Code for SKU ' + props.sku.sku}</h4>
            <div>
              <strong>{props.description}</strong>
            </div>
          </div>
          <button
            onClick={props.close}
            type="button"
            className={classNames({
              close: true,
              invisible: disabled,
            })}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Loading loading={props.requesting} />
          <div
            className={classNames({
              invisible: props.requesting,
            })}>
            <div className="form-group">
              <label htmlFor="lotCodeId">Lot Code</label>
              <input
                type="text"
                className="form-control"
                id="lotCodeId"
                name="lotCodeId"
                value={lotCodeId}
                onChange={e => {
                  setLotCodeId(e.target.value)
                }}
                disabled={disabled}
                maxLength={25}
              />
            </div>
            <div className="form-group">
              <label htmlFor="harvestDateObj" style={{ marginRight: 10 }}>Packaged Date</label>
              <DatePicker
                className={classNames({'form-control': true})}
                disabled={disabled}
                name="harvestDateObj"
                id="harvestDateObj"
                selected={harvestDate}
                onChange={date => setHarvestDate(date)}
                dateFormat="yyyy/MM/dd"                
                autoComplete={'off'}
              />
            </div>
            <div className="form-row">
              <div className="col-12">
                <label htmlFor="thc">Total THC</label> <span style={{ fontSize: 14, color: "gray", paddingLeft: 15 }}>Range {props.sku.thcMin} {getDisplayUnits(props.sku.thcUnits)} to {props.sku.thcMax} {getDisplayUnits(props.sku.thcUnits)}</span>
              </div>
            </div>
            <div className="form-row">
              <div className="col-4">
                <input
                  className="form-control"
                  id="thc"
                  name="thc"
                  type="text"
                  value={thc}
                  onChange={updateThc}
                  disabled={disabled}
                />
              </div>
              <div className="col-1" style={{ alignSelf: "flex-start", paddingBottom: 10, paddingTop: 6} }>
                {getDisplayUnits(props.sku.thcUnits)}
              </div>
              <div className="col-7" style={{ alignSelf: "flex-end"}}>
                <span style={{ paddingLeft: 15, color: "red", display: thcOutOfRange ? "block" : "none" }}>This value falls outside the acceptable range causing potential rejection during receiving.<br />
                  Please contact Category Management team at <a href="mailto:CategoryManagement@aglc.ca">CategoryManagement@aglc.ca</a>.</span>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                <label htmlFor="cbd">Total CBD</label> <span style={{ fontSize: 14, color: "gray", paddingLeft: 15 }}>Range {props.sku.cbMin} {getDisplayUnits(props.sku.cbUnits)} to {props.sku.cbMax} {getDisplayUnits(props.sku.cbUnits)}</span>
              </div>
            </div>
            <div className="form-row">
              <div className="col-4">
                <input
                  className="form-control"
                  id="cbd"
                  name="cbd"
                  type="text"
                  value={cbd}
                  onChange={updateCbd}
                  disabled={disabled}
                />
              </div>
              <div className="col-1" style={{ alignSelf: "flex-start", paddingBottom: 10, paddingTop: 6 }}>
                {getDisplayUnits(props.sku.cbUnits)}
              </div>
              <div className="col-7" style={{ alignSelf: "flex-end"}}>
                <span style={{ paddingLeft: 15, color: "red", display: cbOutOfRange ? "block" : "none" }}>This value falls outside the acceptable range causing potential rejection during receiving.<br />
                  Please contact Category Management team at <a href="mailto:CategoryManagement@aglc.ca">CategoryManagement@aglc.ca</a>.</span>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div
            className={classNames({
              badge: true,
              'badge-success': true,
              invisible: !showSuccess,
            })}>
            Success!
          </div>
          <div
            className={classNames({
              badge: true,
              'badge-danger': true,
              invisible: !showError,
            })}>
            An Error Occurred
          </div>
          <button
            type="button"
            disabled={!areFieldsValid || disabled}
            className="btn btn-orange"
            onClick={submit}>
            {props.mode === MODAL_MODE.ADD && 'Add'}
            {props.mode === MODAL_MODE.EDIT && 'Edit'}
            &nbsp;Lot Code
          </button>

          <button
            onClick={props.close}
            type="button"
            className="btn btn-secondary"
            disabled={props.requesting}>
            Close
          </button>

          {!props.requesting && props.error && (
            <div className="modal-error">{props.errorText}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LotCodeModalView
