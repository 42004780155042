import React from 'react'
import AsnDialogContext from './Context'

interface Props {
  asn: any
  mode: number
  className: string
}

const AsnDialogOpener: React.FunctionComponent<Props> = props => {
  return (
    <AsnDialogContext.Consumer>
      {({open, setASN, setMode}) => {
        const onClick = () => {
          setASN(props.asn)
          setMode(props.mode)
          open()
        }

        return (
          <button className={props.className} onClick={onClick}>
            {props.children}
          </button>
        )
      }}
    </AsnDialogContext.Consumer>
  )
}

export default AsnDialogOpener
