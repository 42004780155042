import React from 'react'
import { MODAL_MODE } from './lotCodeModalView'

const modal = {
  isOpen: false,
  open: () => {},
  close: () => {},
  lotCode: {},
  setLotCode: (lotCode: any) => {},
  mode: MODAL_MODE.ADD,
  setMode: (mode: number) => {}
}

const LotCodeModalContext = React.createContext(modal)
LotCodeModalContext.displayName = 'LotCodeModalContext'

export default LotCodeModalContext
