import React from 'react'
// import { MODAL_MODE } from './CoAModalView'

const modal = {
  isOpen: false,
  open: () => {},
  close: () => {},
  lotCode: {},
  // setLotCode: (lotCode: any) => {},
  // mode: MODAL_MODE.ADD,

}

const CoAModalContext = React.createContext(modal)
CoAModalContext.displayName = 'CoAModalContext'

export default CoAModalContext
