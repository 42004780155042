import AuthStore from '../stores/Auth'
import { Routes } from '../App'

export interface ResponseObj {
  is_error: boolean
  error_content: any
  content: any
}

export default class RestUtilities {
  static get(url: string) {
    return RestUtilities.request('GET', url)
  }

  static delete(url: string) {
    return RestUtilities.request('DELETE', url)
  }

  static put(url: string, data: any) {
    return RestUtilities.request('PUT', url, data)
  }

  static post(url: string, data: any) {
    return RestUtilities.request('POST', url, data)
  }


  private static async request(method: string, url: string, data = null) {
    let isBadRequest = false
    let forbidden = false
    let notFound = false
    let unauthorized = false
    let serverError = false

    let body: any = data //TODO: type here?
    let headers = new Headers()

    headers.set('Authorization', `Bearer ${AuthStore.getToken()}`)
    headers.set('Accept', 'application/json')
    headers.set('Pragma', 'no-cache')
    headers.set('Cache-Control', 'no-cache')

    if (method !== 'GET' && data) {
      if (typeof data === 'object') {
        headers.set('Content-Type', 'application/json')
        body = JSON.stringify(data)
      } else {
        headers.set('Content-Type', 'application/x-www-form-urlencoded')
      }
    }

    /*
      Dynamically import our "fetch".
      Allows for development version to hot-swap in a mock fetch client
      that returns test data, and allows for test data to be excluded from
      production build.
    */
    const fetcher = await import(`./${process.env.REACT_APP_FETCH}`)

    return fetcher.execute(url, {
      method: method,
      headers: headers,
      body: body,
    })
      .then((response: Response) => {
        if (response.status === 401) {
          unauthorized = true
          AuthStore.clear()

           // redirect to sign-in
          if (!response.url.includes('/api/auth/login')) {
            window.location.replace(Routes.SignOut)
          }
        }

        isBadRequest = response.status === 400
        forbidden = response.status === 403
        notFound = response.status === 404
        serverError = response.status >= 500

        let responseContentType = response.headers.get('content-type')
        if (
          responseContentType &&
          responseContentType.indexOf('application/json') !== -1
        ) {
          return response.json()
        } else if (responseContentType && responseContentType.indexOf("application/pdf") !== -1) {
          return response.blob();
        }
         else {
          return response.text()
        }
      })
      .then((responseContent: Promise<any>) => {
        let is_error =
          isBadRequest || forbidden || notFound || unauthorized || serverError
        let response: ResponseObj = {
          is_error: is_error,
          error_content: is_error ? responseContent : null,
          content: is_error ? null : responseContent,
        }
        return response
      })
  }
}
