import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router'

import { default as AuthService } from '../services/Auth'
import { default as MockAuthService } from '../services/MockAuth'
import SignInForm from './SignInForm'
import RoleContext, { USER_ROLE } from './RoleContext'
import { Routes } from '../App'
import LPContext, { DEFAULT_LP } from '../lpLoader/LPContext'
import Auth from '../services/Auth'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [mfa, setMfa] = useState<string | undefined>(undefined)
  const [rememberMe, setRememberMe] = useState(false)
  const [showMfa, setShowMfa] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const roleContext = useContext(RoleContext)
  const lpContext = useContext(LPContext)
  const history = useHistory()

  const doLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError("");
    setLoading(true)
    const response = await AuthService.signIn(username, password, mfa, rememberMe)
    setLoading(false)

    if (response.is_error) {
      setError(
        response.error_content.error_description || response.error_content
      )
      if (response.resetMfa) {
        setMfa(undefined);
      }
    } else if (response.content.token == null && response.content.showMfa) {
      setShowMfa(true);
    } else {
      
      let token = response.content.token
      if (token) {
        let roleFromToken = Auth.getRole(token)
        if (
          roleFromToken === USER_ROLE.ADMIN ||
          roleFromToken === USER_ROLE.AGLC ||
          roleFromToken === USER_ROLE.DEFAULT
        ) {
          roleContext.setRole(roleFromToken)
        }

        if (roleFromToken === USER_ROLE.DEFAULT) {
          lpContext.setLP(Auth.getLP(token))
        } else {
          lpContext.setLP(DEFAULT_LP)
        }

          localStorage.setItem("PasswordChangeRequired", response.content.passwordChangeRequired);
      }

      history.push(Routes.Home)
    }
  }

  return (
    <SignInForm
      username={username}
      password={password}
      mfa={mfa}
      rememberMe={rememberMe}
      handleUsername={setUsername}
      handlePassword={setPassword}
      handleMfa={setMfa}
      handleRememberMe={setRememberMe}
      showMfa={showMfa}
      handleSubmit={doLogin}
      error={error}
      submitting={loading}
    />
  )
}

export default Login
