import RestUtilities from './Base';

export default class ASN {
  static getList(lp: number, offset: number, sortBy: string, filterBy: string, filterByType: string, reverse: boolean) {
    const apiCall = `/api/${lp}/asn/list?sortBy=${sortBy}&&filterBy=${filterBy}&&filterByType=${filterByType}&&size=50&offset=${offset}&&reverse=${reverse}`    
    return RestUtilities.get(apiCall).then((response) => {
      return response
    })
  }

  static getSortedList(lp: number, offset:number, sortBy:string, reverse:number) {
    return RestUtilities.get(`/api/${lp}/asn/list/${sortBy}?size=50&offset=${offset}&reverse=${reverse}`).then((response) => {
      return response
    })
  }



  static getAll(offset: number) {
    return RestUtilities.get(`/api/asn/list/all?size=50&offset=${offset}`).then((response) => {
      return response
    })
  }


  static get(lp: string, POid: string, documentNumber: string) {
    return RestUtilities.get(`/api/${lp}/asn/${POid}/${documentNumber}`).then(
      (response) => {
        return response
      }
    )
  }

  static createSubmit(lp: string, data: any) {
    return RestUtilities.post(`/api/${lp}/asn/`, data).then((response) => {
      return response
    })
  }

  static createDraft(lp: string, data: any) {
    return RestUtilities.post(`/api/${lp}/asn/draft`, data).then((response) => {
      return response
    })
  }

  static amendSubmit(lp: string, data: any) {
    return RestUtilities.put(`/api/${lp}/asn/`, data).then((response) => {
      return response
    })
  }

  static amendDraft(lp: string, data: any) {
    return RestUtilities.put(`/api/${lp}/asn/draft`, data).then((response) => {
      return response
    })
  }

  static startAmend(lp: string, poId: string, documentNumber: number) {
    return RestUtilities.post(`api/${lp}/asn/${poId}/${documentNumber}/startamend`, {}).then((response) => {
      return response
    })
  }

  //api/{lp}/asn/{lineid}/{costcenter}/{docnumber}/coa
  static uploadCoAData(lp: string, lineId:string, costCenter: string, docNumber:string, data: any) {
    return RestUtilities.put(
      `api/${lp}/asn/${lineId}/${costCenter}/${docNumber}/coa`, data).then((response) => {
        return response
      })
  }

  static cancel(lp: string, POid: string, documentNumber: string) {
    return RestUtilities.put(
      `/api/${lp}/asn/${POid}/${documentNumber}/cancel`,
      {} // TODO: verify this param doesnt break things
    ).then((response) => {
      return response
    })
  }

  static remove(lp: string, POid: string, documentNumber: string) {
    return RestUtilities.put(
      `/api/${lp}/asn/${POid}/${documentNumber}/remove`,
      {} // TODO: verify this param doesnt break things
    ).then((response) => {
      return response
    })
  }

  // static uploadCoA(lp: string, docNumber: string, file: File) {  
  //   //might be some issue mapping to paramaters
  //   // const coaUploader = new CoAUploader();

  //   return CoAUploader.sendCoA(file, lp, docNumber);
  //   // return RestUtilities.put(
  //   //   `/api/${lp}/asn/${docNumber}/uploadCoA`, {file}
  //   // ).then((response) => response)

  // }

  static Status = {
    draft: 'draft',
    submitted: 'submitted',
    cancelled: 'cancelled',
    confirmed: 'confirmed',
    partial: 'partial',
    received: 'received',
    booked: 'booked',
    removed: 'removed',
  }

  static canAmend(status: string) {
    if (
      status.toLowerCase().trim() === ASN.Status.submitted ||
      status.toLowerCase().trim() === ASN.Status.cancelled ||
      status.toLowerCase().trim() === ASN.Status.booked ||
      status.toLowerCase().trim() === ASN.Status.received ||
      status.toLowerCase().trim() === ASN.Status.removed
    ) {
      return false
    }
    return true
  }

  static canCancel(status: string) {
    if (
      status.toLowerCase().trim() === ASN.Status.confirmed ||
      status.toLowerCase().trim() === ASN.Status.partial
    ) {
      return true
    }
    return false
  }

  static canRemove(status: string) {
    if (
      status.toLowerCase().trim() === ASN.Status.draft ||
      status.toLowerCase().trim() === ASN.Status.received
    ) {
      return true
    }
    return false
  }

  static isDraft(status: string) {
    if (!status || status.toLowerCase().trim() === ASN.Status.draft) {
      return true
    }
    return false
  }
}
