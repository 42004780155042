import React, { Component } from 'react'
import './AsnUploadForm.scss'
import CoAUploader from '../services/CoAUpload'
import ToolTipView from '../toolTipView/ToolTipView'
import CoAModalContext from './context'
import GenericModal from '../modal/Modal'
import CoAModal from './CoAModal'
import Saving from '../loading/Saving'
import { toHtml } from '@fortawesome/fontawesome-svg-core'
interface Props {
    // asn: any //TODO: properly define asn
    // uploadFunction: any
    lp: string
    harvestDate: string
    lotCode: string
    sku: any
    packagingDate:string,
    header:any,
    asn:any
    disabled:boolean
    saveAsn(): Promise<any>
    history: any
    coAUploadCallBack: (isCoAUplaoded: boolean, intialLoad: boolean)  => void //if the coA is empyt we have a callback to update the parent component
    preOpen: () => boolean
}
interface S {
    selectedFile: File | null
    errorExists: boolean
    uploadSuccess:boolean
    uploadName: string
    disabled: boolean;
    isModalOpen: boolean;
    setIsModalOpen: boolean;
    uploading: boolean



}

export class CoAUploadButton extends Component<Props, S>{
    constructor(props: any) {
      super(props)
      this.state = {
        selectedFile: null,
        errorExists: false,
        uploadSuccess: false,
        disabled: this.props.disabled !== undefined ? this.props.disabled : true,
        uploadName: this.props.asn.qvCoa !== undefined ? this.props.asn.qvCoa.trim() : '',
        isModalOpen: false,
        setIsModalOpen: false,
        uploading: false
      }

      this.onFileChange = this.onFileChange.bind(this);


      if (this.props.asn.qvUpf === "Y" && (this.props.asn.qvCat?.trim() !== "" || this.props.asn.qvCoa?.trim() !== "")) {
        this.props.coAUploadCallBack(true, true)
      }
  }

  onFileChange = async (selectedFiles: FileList|null) => {
    if (selectedFiles) {
      return this.props.saveAsn()?.then(response => {
        if (response.error) {
          throw Error('Issue saving ASN before COA Upload');
        }

        
        this.setState({ 
          selectedFile: selectedFiles[0],
          uploading: true
        });
        const currFile = selectedFiles[0];
        if (currFile !== undefined && currFile.type === 'application/pdf') {

          let header = { ...this.props.header };
          header.documentNumber = response.documentNumber;
          header.costCenter = response.costCenter;

          let asn = { ...this.props.asn };
          asn.documentNumber = response.documentNumber;
          asn.costCenter = response.costCenter;

          let resultAsn  = response?.details?.find((detail: any) => detail.assigned == this.props.asn.assigned);
          if (resultAsn == null) {
            throw 'Error finding details line';
          }

          asn.lineID = resultAsn.lineID;

          return CoAUploader.sendCoA(
            currFile,
            this.props.harvestDate,
            this.props.lotCode,
            this.props.sku,
            this.props.lp,
            header,
            asn

          ).then((response) => {
            if (response instanceof Error) {
              throw Error('Issue Uploading CoA')
            } else {
              this.setState({
                uploadSuccess: true,
                uploadName: (response as string).trim(),
                uploading: false
              });
              return this.callBackUpdate(true)
            }
          }).catch((err) => {            
            console.log(err);


            this.setState({
              uploading: false,
              errorExists: true
            });
            return this.callBackUpdate(false)
          }).finally(() => {
              let lpString = this.props.lp.toString().trim();
              let ordNumString = this.props.header.originalOrderNumber.toString().trim();
              let docNumString = response.documentNumber.toString().trim();

              this.props.history.push(`/asn/amend/${lpString}/${ordNumString}/${docNumString}`)

              if (this.state.isModalOpen) {
                  this.closeModal();
              }
          });
        } else {
          this.setState({
            uploading: false,
            errorExists: true
          })
          return this.callBackUpdate(false)
        }
      });
    }
  }

  callBackUpdate(setting: boolean) {
    if (!(this.props.asn.qvUpf === "Y" && (this.props.asn.qvCat?.trim() !== "" || this.props.asn.qvCoa?.trim() !== ""))) {
      this.props.coAUploadCallBack(setting, false)
    }
  }



  fileData = () => {
    if (this?.state?.selectedFile) {
      return (
        <div>
          <p>File Name: {this.state.selectedFile.name}</p>
        </div>
      );
    }
  };

  buttonStyle = () => {
    const defaultStyle = "file btn btn-secondary";
    return this.state.errorExists === true
      ? defaultStyle + ' ' + 'redBorder'
      : this.props.asn.qvUpf === "Y" || this.state.uploadSuccess === true
        ? defaultStyle + ' ' + 'greenBorder'
        : defaultStyle;
  };

  isbuttonDisabled = () => {
    if (this.state.uploadSuccess !== undefined){
      return this.state.uploadSuccess;
    } else {
      return false;
    }
  }
  
  create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  coaUploadButton = () => {
    const inputId = "coaUpload" + this.props.sku + this.create_UUID();
    return (

      <div style={{textAlign:"center"}}>
        <input onClick={(e) => this.btnClick(e)} onChange={(e) => this.onFileChange(e.target.files)} type="file" name="file" id={inputId}  accept="application/pdf"/>
      <label className="hiddenInput" htmlFor={inputId}>
        <div style={{ padding:"5px"}} className={this.buttonStyle()}>
          CoA
        </div>
      </label>
    </div>
    );
  }
  btnClick = (e:React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (e.isTrusted) {
      if (!this.props.preOpen()) {
        e.preventDefault()
      }
    }
  }
  openModal = () => {
      // setIsModalOpen(true)
      if (!this.props.preOpen()) {
        return;
      }
      this.setState({
        isModalOpen: true
      });
    }

  closeModal = () => {
      // setIsModalOpen(false)
      this.setState({
        isModalOpen: false
      });
    }

  coAModalOpenerWithFileName = () => {
    return (
      <CoAModalContext.Provider
        value={{
          isOpen: this.state.isModalOpen,
          open: this.openModal,
          close: this.closeModal,
          lotCode: {},

        }}
      >
      <CoAModalContext.Consumer>
        {({open}) => {
          const openAction = () => {              
            open();
          }

          const inputId = "modalDeploy" + this.props.sku + this.props.asn.lineID + this.create_UUID();;

          return (
            <ToolTipView placement="top" title={`Uploaded: ${this.state.uploadName}`}>
              <div style={{textAlign:"center"}}>
              <input onClick={openAction}  style={{"display":"none"}} id={inputId}  accept="application/pdf"/>
                <label className="hiddenInput" htmlFor={inputId}>
                <div style={{ padding:"5px"}} className={this.buttonStyle()}>
                    CoA
                </div>
              </label>
              </div>

            </ToolTipView>
          )
        }}
      </CoAModalContext.Consumer>
      <CoAModalContext.Consumer>
        {({ isOpen }) => {
          return (
            <GenericModal isOpen={isOpen}>
              {(
                <CoAModal
                  close= {this.closeModal}
                  coAFileName={`${this.state.uploadName}`}
                  asnStatus={this.props.asn.lineStatus}
                  header={this.props.header}
                  asn={this.props.asn}
                  lp={this.props.lp}
                  harvestDate={this.props.harvestDate}
                  lotCode={this.props.lotCode}
                  sku={this.props.sku}
                  packagingDate={this.props.packagingDate}
                  key={`${this.state.uploadName}`}
                />
              )}
            </GenericModal>
          )
        }}
      </CoAModalContext.Consumer>
    </CoAModalContext.Provider>

    );
  }

  render() {
    return(
      <div style={{"border":"none!important", "outline":"none!important", "boxShadow": "none!important"}}>

        {this.state.uploading === true
        ? <Saving/>
        : (this.props.asn.qvUpf === "Y" && (this.props.asn.qvCat?.trim() !== "" || this.props.asn.qvCoa?.trim() !== "")
        || (this.state.uploadName.trim() !== null && this.state.uploadName.trim() !== ''))
          ? this.coAModalOpenerWithFileName()
          : this.coaUploadButton()
        }
      </div>
    )
  }
}

export default CoAUploadButton;
