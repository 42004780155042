import React, { useState, useEffect, useContext, Context } from 'react'
import { useHistory } from 'react-router-dom'

import { default as LPService } from '../services/LP'
import LPContext, { LP, DEFAULT_LP, LPContextInterface } from './LPContext'
import { NONE_SELECTED } from '../select'
import LPLoaderView from './lpLoaderView'
import { Routes } from '../App'

interface Props {
  default?: string
  disabled: boolean
  handleSelected: (lp: LP) => void
}

const LPLoader = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [selected, setSelected] = useState(NONE_SELECTED)
  const [errorMsg, setErrorMsg] = useState('')
  const [LPs, setLPs] = useState<LP[]>([])

  const history = useHistory()
  const lpContext : LPContextInterface = useContext(LPContext)

  const handleSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let index: any = e.target.value // TODO: probably shouldn't be any type
    if (index !== NONE_SELECTED) {
      setSelected(index)
    }
  }

  useEffect(() => {
    if (confirmed) {
      confirm();
    } else {
      unconfirm();
    }
  }, [confirmed])

  const confirm = () => {
    props.handleSelected(LPs[selected as any]);
    LPService.SetInSession(LPs[selected as any]);
  }

  const unconfirm = () => {
    setSelected(NONE_SELECTED)
    lpContext.setLP(DEFAULT_LP)
    props.handleSelected(DEFAULT_LP)

    history.push(Routes.Home)
  }

  const load = async () => {
    //get from cookie
    let selectedLP = LPService.GetFromSession();

    if (props.default && !selectedLP) {
      setSelected(props.default)
    } else {   
      setErrorMsg('')
      setLoading(true)
      setDone(false)

      const response = await LPService.getList()

      setLoading(false)

      if (response.is_error) {
        setErrorMsg(response.error_content)
      } else {
        let lps = new Set<LP>()
        let selectedLpId = null;

        if (lpContext.LP.id !== DEFAULT_LP.id) {
          selectedLpId = lpContext.LP.id;
        } else if (selectedLP != null) {
          selectedLpId = selectedLP.id;
        }

        for (let i = 0; i < response.content.value.length; i++) {
          lps.add(response.content.value[i]);         
        }

        const sortedLps = Array.from(lps).sort((lp1, lp2) => {
            return lp1.id - lp2.id;
        });

        let selectedIndex = -1;
        if (selectedLpId != null) {
          for (let j = 0; j < sortedLps.length; j++) {
            if (sortedLps[j].id === selectedLpId) {
              selectedIndex = j;
              setSelected((j).toString());
              break;
            }
          }
        }        

        setLPs(sortedLps)
        setDone(true)

        if (selectedIndex > -1) {
          setConfirmed(true);
        }
      }
    }
  }

  const doLoad = () => {
    //lpContext.setLP(DEFAULT_LP);
    load()
  }

  useEffect(doLoad, [])

  return (
    <LPLoaderView
      lps={LPs}
      selectedLP={selected}
      disabled={props.disabled}
      handleSelected={handleSelected}
      loading={loading}
      done={done}
      errorMsg={errorMsg}
      confirmed={confirmed}
      setConfirmed={setConfirmed}
    />
  )
}

export default LPLoader
