import React from "react";

import { parseDateString } from "../dates";

import "./lotCodeManager.scss";
import LotCodeModalOpener from "./lotCodeModalOpener";
import { MODAL_MODE } from "./lotCodeModalView";
import { USER_ROLE, CanEdit } from "../signin/RoleContext";

interface Props {
  lotCode: any;
  userRole: USER_ROLE;
}

const LotCodeListItem = (props: Props) => {
  return (
    <div className="table-row">
      <div>{props.lotCode.sku}</div>
      <div>{props.lotCode.lotCodeId}</div>
      <div>{parseDateString(props.lotCode.harvestDate)}</div>
      <div className="value">
        <span style={{color:props.lotCode.thcOutOfRange ? "inherit" : "red"} }>{props.lotCode.thc}</span> {props.lotCode.thcUnits}
      </div>
      <div className="value">
        <span style={{ color: props.lotCode.cbdOutOfRange ? "inherit" : "red" }}>{props.lotCode.cbd}</span> {props.lotCode.cbdUnits}
      </div>
      <div>
        {CanEdit(props.userRole) && (
          <LotCodeModalOpener
            type={MODAL_MODE.EDIT}
            lotCode={props.lotCode}
            disabled={props.lotCode.statusFlag.trim() === "Y"}
          >
            Edit
          </LotCodeModalOpener>
        )}
      </div>
    </div>
  );
};

export default LotCodeListItem;
