import React, { useState } from 'react'
import AsnDialogView from './AsnDialogView'
import ASN from '../services/ASN'
import { MODAL_TYPE } from './Context'

interface Props {
  asn: any
  mode: number
  close: () => void
}

const AsnDialog = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [success, setSuccess] = useState(false)

  const doThing = async () => {
    setLoading(true)
    setError(false)
    setErrorMsg('')

    let response = null
    switch (props.mode) {
      case MODAL_TYPE.CANCEL:
        response = await ASN.cancel(
          props.asn.licensedProducer,
          props.asn.originalOrderNumber,
          props.asn.documentNumber
        )
        break
      case MODAL_TYPE.REMOVE:
        response = await ASN.remove(
          props.asn.licensedProducer,
          props.asn.originalOrderNumber,
          props.asn.documentNumber
        )
      // case MODAL_TYPE.UPLOAD:
      //   response = async (file: File) => {
      //     return await ASN.uploadCoA(
      //       props.asn.licensedProducer,
      //       props.asn.documentNumber,
      //       file
      //     )
      //   }



        break
    }

    setLoading(false)
    if (response) {
      if (response.is_error) {
        setError(true)
        setErrorMsg(response.error_content)
      } else {
        setSuccess(true)
        setError(false)
      }
    } else {
      setError(true)
    }
  }

  return (
    <AsnDialogView
      asn={props.asn}
      mode={props.mode}
      success={success}
      loading={loading}
      error={error}
      errorMsg={errorMsg}
      execute={() => doThing()}
      onClose={props.close}
    />
  )
}

export default AsnDialog
