import RestUtilities from "./Base";
import { LP as LPType } from "../lpLoader/LPContext";

export default class LP {
  static getList() {
    return RestUtilities.get("/api/account/lp/list/").then((response) => {
      return response;
    });
  }

  static GetFromSession(): LPType | null {
    var currLP = sessionStorage.getItem('currentLP');
    var parsed: LPType | null = null;

    if (currLP) {
      parsed = JSON.parse(currLP);
    }


    return parsed;
  }

  static SetInSession(currVal: LPType): void {
    sessionStorage.setItem('currentLP', JSON.stringify(currVal));
  }
}
