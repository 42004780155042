import React from 'react'
import Loading from '../loading/Loading'
import LotCodeListItem from './lotCodeListItem'
import { USER_ROLE } from '../signin/RoleContext'
import { SKU } from '../skuLoader/skuLoaderView'

interface Props {
  loading: boolean
  done: boolean
  errorMsg: string
  lotCodes: any[]
  userRole: USER_ROLE
  sku: SKU | undefined
}

const LotCodeListView = (props: Props) => {
  let lotCodeLineItems: JSX.Element[] = []
  props.lotCodes.forEach((item, index) => {
    lotCodeLineItems.push(
      <LotCodeListItem
        lotCode={item}
        key={index}
        userRole={props.userRole}
      />,
    )
  })

  return (
    <div>
      <Loading show={props.loading} />
      {props.errorMsg && !props.loading && (
        <div className="text-danger">{`An Error Occurred: ${props.errorMsg}`}</div>
      )}

      {!props.loading && !props.errorMsg &&
        <>
      <div className="table">
        <div className="table-header" key="-1">
          <div>SKU</div>
          <div>Lot Code</div>
          <div>Packaged Date</div>
          <div>Total THC</div>
          <div>Total CBD</div>
          <div>Actions</div>
        </div>
        {lotCodeLineItems}
        </div>
      </>
      }
    </div>
  )
}

export default LotCodeListView
