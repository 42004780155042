import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import SKULoader from '../skuLoader/skuLoader'
import LotCodeListView from './lotCodeListView'
import { SKU } from '../skuLoader/skuLoaderView'
import PO from '../services/PO'
import LotCodeModalContext from './context'
import GenericModal from '../modal/Modal'
import LotCodeModal from './lotCodeModal'
import { MODAL_MODE } from './lotCodeModalView'
import LotCodeModalOpener from './lotCodeModalOpener'
import RoleContext, { CanEdit } from '../signin/RoleContext'
import LPContext, { DEFAULT_LP } from '../lpLoader/LPContext'

const LotCodeManager = () => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [lotCodes, setLotCodes] = useState<any[]>([])
  const [selectedSKU, setSelectedSKU] = useState<SKU>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedLotCode, setSelectedLotCode] = useState({})
  const [modalType, setModalType] = useState(MODAL_MODE.ADD)

  const roleContext = useContext(RoleContext)
  const lpContext = useContext(LPContext)

  let { LPid } = useParams() as any;

  const doLoadLotCodes = () => {
    loadLotCodes()
  }

  const loadLotCodes = async () => {
    if(isModalOpen) {
      return
    }

    if (!LPid) {
      setErrorMsg('Select LP')
      return
    }

    if (selectedSKU) {
      setLoading(true)
      setDone(false)
      setErrorMsg('')

      const response = await PO.getLotCodes(LPid, selectedSKU.sku)

      setLoading(false)
      if (response.is_error) {
        setErrorMsg(response.error_content)
      } else {
        let lineItems = []
        for (let i = 0; i < response.content.value.length; i++) {
          lineItems.push(response.content.value[i])
        }

        setLotCodes(lineItems)
        setDone(true)
      }
    }
  }

  const handleSelectedSKU = (sku: SKU) => {
    setSelectedSKU(sku)
  }

  useEffect(doLoadLotCodes, [selectedSKU, isModalOpen])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <div className="row">
        <div className="col-md">
          <h1>Lot Codes {(lpContext.LP.id !== DEFAULT_LP.id) ? ` - ${lpContext.LP.id}`: ' - All LPs'}</h1>
        </div>
      </div>

      <LotCodeModalContext.Provider
        value={{
          isOpen: isModalOpen,
          open: openModal,
          close: closeModal,
          lotCode: selectedLotCode,
          setLotCode: setSelectedLotCode,
          mode: modalType,
          setMode: setModalType,
        }}
      >
        <SKULoader disabled={false} handleSelectedSKU={handleSelectedSKU}>
          {CanEdit(roleContext.role) && (
            <div className="add">
              {selectedSKU && (
                <LotCodeModalOpener type={MODAL_MODE.ADD} disabled={false}>
                  + Add Lot Code
                </LotCodeModalOpener>
              )}
            </div>
          )}
        </SKULoader>

        <LotCodeListView
          loading={loading}
          done={done}
          errorMsg={errorMsg}
          lotCodes={lotCodes}
          userRole={roleContext.role}
          sku={selectedSKU}
        />

        <LotCodeModalContext.Consumer>
          {({ isOpen, close, lotCode, mode }) => {
            return (
              <GenericModal isOpen={isOpen}>
                {selectedSKU && (
                  <LotCodeModal
                    mode={mode}
                    sku={selectedSKU}
                    description={selectedSKU.description}
                    lotCode={lotCode}
                    close={close}
                  />
                )}
              </GenericModal>
            )
          }}
        </LotCodeModalContext.Consumer>
      </LotCodeModalContext.Provider>
    </div>
  )
}

export default LotCodeManager
