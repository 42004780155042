import React from 'react'
import { Redirect } from 'react-router-dom'

import { Routes } from '../App'
import Auth from '../services/Auth'

const SignOut = () => {
  if (Auth.isSignedInIn()) {
    Auth.signOut();
  }
  return <Redirect to={Routes.SignIn} push />;
}

export default SignOut
