import React from 'react'
import { FixedSizeList, FixedSizeListProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import Loading from '../loading/Loading'
import ASNListItemView from './ASNListItemView'
import { USER_ROLE } from '../signin/RoleContext'

function InfiniteScrollWrapper({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Callback function responsible for loading the next page of items.
  loadNextPage,

  userRole
}: {
  hasNextPage: boolean
  isNextPageLoading: boolean
  items: any[]
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<any>
  userRole: USER_ROLE
}) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = (startIndex: number, stopIndex: number) => {
    if (isNextPageLoading) {
      return null
    }
    return loadNextPage(startIndex, stopIndex)
  }

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length

  // Render an item or a loading indicator.
  const Item = ({
    index,
    style,
  }: {
    index: number
    style: React.CSSProperties
  }) => {
    let content
    if (!isItemLoaded(index)) {
      return (
        <div style={style}>
          Loading...
          <Loading loading={true} show={true} />
        </div>
      )
    } else {
      content = items[index]
    }

    return (
      <div className="ASNRow" style={style}>
        <ASNListItemView asn={content} userRole={userRole} />
      </div>
    )
  }

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={1}
    >
      {({ onItemsRendered, ref, ...props }) => (
        <FixedSizeList
          height={600}
          itemSize={60}
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={ref}
          {...props as any}
        >
          {Item}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  )
}

export default InfiniteScrollWrapper
