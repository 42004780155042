import React, { useState, useEffect, useContext } from 'react'

import PO from '../services/PO'
import SkuLoaderView, { SKU } from './skuLoaderView'
import { NONE_SELECTED } from '../select'
import LPContext from '../lpLoader/LPContext'

interface Props {
  default?: string
  disabled: boolean
  handleSelectedSKU: (sku: SKU) => void
}

const SKULoader: React.FunctionComponent<Props> = props => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [selected, setSelected] = useState(NONE_SELECTED)
  const [errorMsg, setErrorMsg] = useState('')
  const [skus, setSkus] = useState<SKU[]>([])

  const lpContext = useContext(LPContext)

  const load = async () => {
    setErrorMsg('')
    setLoading(true)
    setDone(false)

    const response = await  PO.getSkus(lpContext.LP.id)

    setLoading(false)

    if (response.is_error) {
      setErrorMsg(response.error_content)
    }
    else {
      var skus = new Set<SKU>()
      for (var i = 0; i < response.content.value.length; i++) {
          skus.add(response.content.value[i])
      }

      setSkus(Array.from(skus))
      setDone(true)
    }
  }

  // fetch on component mount
  const doLoad = () => {
    load()
  }
  useEffect(doLoad, [])

  const handleSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
      let index: any = e.target.value

      if(index !== NONE_SELECTED) {
          setSelected(index)
          props.handleSelectedSKU(skus[index])
      }
  }

  return (
    <SkuLoaderView
      skus={skus}
      selectedSKU={selected}
      disabled={props.disabled}
      handleSelected={handleSelected}
      loading={loading}
      done={done}
      errorMsg={errorMsg}
      children={props.children}
    />
  )
}

export default SKULoader
