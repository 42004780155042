import React from 'react';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const CanadaPhoneInput = (props) => {
    return (
      <PhoneInput
        value={props.value}
        disabled={props.disabled}
        country='ca'
        onlyCountries={['ca']}
        maxLength='14'
        className='form-control'
        placeholder='(xxx) xxx-xxxx'
        onChange={props.onChange} />
    )
}

export default CanadaPhoneInput
