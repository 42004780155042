import React from 'react'
import classNames from 'classnames'
import Loading from '../loading/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MODAL_TYPE } from './Context'
// import CoAUploadButton from '../asnCoAUpload/CoAUploadButton'
import { execute } from '../services/Fetch'

interface Props {
  loading: boolean
  error: boolean
  errorMsg: string
  success: boolean
  onClose: () => void
  execute: (asn: any) => void
  asn: any
  mode: number
}

const AsnDialogView = (props: Props) => {
  let action = ''
  let actionBody = null;
  switch(props.mode) {
    case MODAL_TYPE.CANCEL:
      action = 'Cancel'
      actionBody = `${action} ASN ${props.asn.documentNumber}?`

      break
    case MODAL_TYPE.REMOVE:
      action = 'Remove'
      actionBody = `${action} ASN ${props.asn.documentNumber}?`
      break
    case MODAL_TYPE.UPLOAD:
      action = 'Upload Certificate of Analysis'
      actionBody = (
        <div></div>
          // <AsnCoAUploadForm asn={props.asn} uploadFunction={execute}/>
      )
      break
  }

  const goButton = () => {
    if (props.mode === MODAL_TYPE.CANCEL || props.mode === MODAL_TYPE.REMOVE){
      return (
        <button className='btn btn-orange' onClick={props.execute}>{props.error ? 'Retry' : 'Go'}</button>
      );
    }
  }


  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header" style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>{action}</div>
          <button className='btn' onClick={props.onClose}>
            <FontAwesomeIcon icon='times' />
          </button>
        </div>
        <div className="modal-body">

          <div
            className={
              classNames({
                'd-none': !(!props.loading && !props.success)
              })
            }>
            {actionBody}
          </div>

          <div
            className={
              classNames({
                'd-none': !(props.loading && !props.success)
              })
            }>
            <Loading show={true} />
          </div>

          <div
            className={
              classNames({
                'd-none': !(!props.loading && props.error),
                'text-danger': true
              })
            }>
            {`An Error Occurred: ${props.errorMsg}`}
          </div>

          <div
            className={
              classNames({
                'd-none': !(props.success),
              })
            }>
            {'Done!'}
          </div>

        </div>

        <div className="modal-footer">
          <div
            className={
              classNames({
                'd-none': props.loading || props.success,
              })
            }>
            {goButton()}




          </div>

        </div>
      </div>
    </div>
  )
}

export default AsnDialogView
