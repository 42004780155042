import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CoAUploadButton from "../asnCoAUpload/CoAUploadButton";
import ToolTip from "rc-tooltip";

export const LOT_CODE_UNSELECTED = "";
/**
 * Represents a single line item from an ASN.
 */
export class ASNDetail extends Component {
    
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.numberCasesChanged = this.numberCasesChanged.bind(this);
        this.lotCodeChanged = this.lotCodeChanged.bind(this);

        this.addLotCode = this.addLotCode.bind(this);
        this.removeLotCode = this.removeLotCode.bind(this);

        this.isNullOrWhitespace = this.isNullOrWhitespace.bind(this);
        this.isPartiallyFilled = this.isPartiallyFilled.bind(this);
        this.isInValidQuantity = this.isInValidQuantity.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.notDivisible = this.notDivisible.bind(this);
        // this.requiresCoA =  this.requiresCoA.bind(this);
        this.onCoAChange = this.onCoAChange.bind(this);


        this.state ={

        }
    }
    notDivisible(value) {
        return !(value % this.props.po.eachesPerCase === 0);
    }

    handleChange(name, value) {
        this.props.onChange(this.props.po, this.props.index, name, value, ); // propogate change up to parent
    }

    lotCodeChanged(event) {
        this.props.po.lotCodes.forEach((lotCode) => {
            if (lotCode.id === event.target.value) {
                this.handleChange("harvestDate", lotCode.harvestDate);
                this.handleChange("lotCode", lotCode.lotCodeId.trim());
            }
        });
    }

    increment(value) {
        let valueAsNumber = parseInt(value, 10);
        if (isNaN(valueAsNumber)) {
            valueAsNumber = 0;
        }

        valueAsNumber += this.props.po.eachesPerCase;
        if (valueAsNumber % this.props.po.eachesPerCase != 0) {
            valueAsNumber -= valueAsNumber % this.props.po.eachesPerCase;
        }
        this.handleChange("openQuantity", valueAsNumber);
    }
    decrement(value) {
        let valueAsNumber = parseInt(value, 10);
        if (isNaN(valueAsNumber)) {
            valueAsNumber = 0;
        }

        valueAsNumber -= this.props.po.eachesPerCase;
        if (valueAsNumber % this.props.po.eachesPerCase != 0) {
            valueAsNumber -= valueAsNumber % this.props.po.eachesPerCase;
        }

        if (valueAsNumber < 0) {
            valueAsNumber = 0;
        }
        this.handleChange("openQuantity", valueAsNumber);
    }
    numberCasesChanged(event) {
        this.handleChange(event.target.name, event.target.value);
    }
    addLotCode() {
        this.props.addLotCode(this.props.po.sku);
    }
    removeLotCode() {
        this.props.removeLotCode(this.props.po.sku, this.props.index);
    }
    isNullOrWhitespace(input) {
        if (typeof input == "string") return !input || !input.trim();
        else if (typeof input == "object") return input == null;
        return false;
    }
    isInValidQuantity(input) {
        if (typeof input == "number") return false;
        if (typeof input == "string") {
            let parsed = parseInt(input, 10);
            if (isNaN(parsed)) {
                return true;
            }
            return false;
        }
    }
    isPartiallyFilled(values) {
        let countFilledFields = 0;
        if (this.isNullOrWhitespace(values.lotCode)) countFilledFields++;

        if (this.isInValidQuantity(values.openQuantity)) countFilledFields++;

        return countFilledFields > 0 && countFilledFields < 2;
    }
    onCoAChange(hasCoAValue, intialCoALoad) {       
        if (!intialCoALoad) {
            this.handleChange("hasCoA", hasCoAValue)
            this.props.onCoAUpdate(hasCoAValue)
        }        
    }

    generateLotCodeId(lotCode, harvestDate) {
        return `${lotCode.trim()}_${harvestDate}`;
    }
    render() {
        // merge po and asn data
        let values = {
            harvestDate: "",
            lotCode: "",
            openQuantity: "",
            sku: this.props.po.sku,
            description: this.props.po.description,
        };
        values = Object.assign(values, this.props.asn);

        let button =
            this.props.index === 0 ? (
                <ToolTip placement="top" overlay={<span>Add LOT# to SKU</span>}>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={this.addLotCode}
                >
                    +Lot#
                    </button>
                </ToolTip>
            ) : (
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={this.removeLotCode}
                >
                    <FontAwesomeIcon icon="trash-alt" />
                </button>
            );

        const coAUploadButton = () => {
            if  (values.harvestDate !== '')  {
                return (
                    <td>
                        <CoAUploadButton 
                            harvestDate={values.harvestDate}
                            lotCode={values.lotCode}
                            sku={values.sku}
                            lp={this.props.lp}
                            header={this.props.header}
                            asn={this.props.asn}
                            saveAsn={this.props.saveAsn}
                            history={this.props.history}
                            coAUploadCallBack={this.onCoAChange}
                            preOpen={this.props.preOpen}
                        />
                    </td>
                )
            }
        }



        // hide 'barcoded' fields
        let barcoded = this.props.po.referenceField.trim() === "BF";
        if (this.props.asn.lotCode) barcoded = false; // show rows that may have been added before the SKU was barcoded to allow for removal

        // hide detail line item if there's no line ID, when in read-only mode, and non-draft mode
        let hide = (!values.lineID && this.props.readOnly) || barcoded;

        // disable all fields while submitting
        let readOnly = this.props.readOnly || this.props.submitting;

        let lotCodes = [
            <option key={LOT_CODE_UNSELECTED} value={LOT_CODE_UNSELECTED}>
                Select a Lot Number
            </option>,
        ];
        if (this.props.po.lotCodes.length === 0) {
            lotCodes = [
                <option
                    key={LOT_CODE_UNSELECTED}
                    value={LOT_CODE_UNSELECTED}
                    disabled
                >
                    No Lot Numbers Available
                </option>,
            ];
        } else {
            let oldLotCode = true;
            let asnLotCode = this.props.asn.lotCode
                ? this.generateLotCodeId(
                      this.props.asn.lotCode,
                      this.props.asn.harvestDate
                  )
                : "";
            this.props.po.lotCodes.forEach((lotCode) => {
                lotCodes.push(
                    <option key={lotCode.id} value={lotCode.id}>
                        {lotCode.description}
                    </option>
                );

                if (asnLotCode == lotCode.id) {
                    oldLotCode = false;
                }
            });

            // for lot codes that were entered prior to the 'Add Lot Code' feature
            if (asnLotCode && oldLotCode) {
                lotCodes.push(
                    <option key={asnLotCode} value={asnLotCode}>
                        {asnLotCode + " (Not found in F62ASNL)"}
                    </option>
                );
            }
        }

        return (
            <tr
                className={this.props.index === 0 ? "" : "child"}
                style={{
                    display: hide ? "none" : "table-row",
                }}
            >
                <td>{this.props.index === 0 ? this.props.po.sku : ""}</td>
                <td>
                    <select
                        value={
                            values.lotCode.trim()
                                ? this.generateLotCodeId(
                                      values.lotCode,
                                      values.harvestDate
                                  )
                                : LOT_CODE_UNSELECTED
                        }
                        name="lotCode"
                        className={
                            this.isNullOrWhitespace(values.lotCode) &&
                            this.isPartiallyFilled(values)
                                ? "form-control error"
                                : "form-control"
                        }
                        onChange={this.lotCodeChanged}
                        disabled={readOnly}
                        required
                    >
                        {lotCodes}
                    </select>
                </td>
                <td>{this.props.po.eachesPerCase}</td>
                <td>
                    <input
                        type="search"
                        value={values.openQuantity}
                        name="openQuantity"
                        className={
                            (this.isInValidQuantity(values.openQuantity) &&
                                this.isPartiallyFilled(values)) ||
                            this.props.exceedsQuantity()
                                ? "form-control error"
                                : "form-control"
                        }
                        onChange={this.numberCasesChanged}
                        disabled={readOnly}
                        min="0"
                        required
                    />
                    {/*<div>
                        <button className={'btn btn-orange' + (readOnly? ' d-none' : '')} disabled={readOnly} type="button" onClick={(event) => this.increment(values.openQuantity)}><i className="fas fa-plus"></i></button>
                        <span style={{ margin: '5px' }}>{values.openQuantity}</span>
                        <button className={'btn btn-orange' + (readOnly? ' d-none' : '')} disabled={readOnly} type="button" onClick={(event) => this.decrement(values.openQuantity)}><i className="fas fa-minus"></i></button>
                    </div>*/}
                    <div
                        style={{
                            color: "red",
                            display: this.props.exceedsQuantity()
                                ? "block"
                                : "none",
                        }}
                    >
                        This Quantity exceeds the amount available on order.
                    </div>
                    <div
                        style={{
                            color: "red",
                            display: this.notDivisible(values.openQuantity)
                                ? "block"
                                : "none",
                        }}
                    >
                        This Quantity should be a multiple of{" "}
                        {this.props.po.eachesPerCase}.
                    </div>
                </td>
                <td>{this.props.po.quantityOnOrder}</td>
                {coAUploadButton()}
                <td className={readOnly ? "d-none" : ""}>{button}</td>
            </tr>
        );
    }
}
